<template>
    <div class="icph-timeline"
        :class="{
            'icph-loading': loading,
            'icph-not-centered': !is_centered
        }"
        >
        <loader />
        <div class="icph-timeline-viewport" ref="viewport" v-touch:swipe="on_swipe">
            <button class="icph-timeline-center" @click="center_viewport">Present Day</button>
            <p class="icph-timeline-empty" v-if="is_empty">There are no events to display</p>
            <div
                v-else
                class="icph-timeline-inner"
                :style="{
                    'transform': 'translate3d(' + offset_x + '%, 0, 0)'
                }"
                ref="timeline-inner"
                >
                <div class="icph-timeline-middle"></div>
                <ul>
                    <template v-for="(option, index) in dataset" :key="index">
                        <li
                            v-if="option.date"
                            :data-date="option.date || ''"
                            @click="select_item(option)"
                            :class="{
                                'icph-current': option.current,
                                'icph-has-documents': option.has_documents,
                                'icph-quarter': !option.date,
                            }"
                            >
                            <span class="icph-timeline-item-date" v-if="option.date">{{ option.date }}</span>
                            <div class="icph-timeline-content">
                                <div class="icph-timeline-content-wrapper">
                                    {{ option.content || option }}
                                </div>
                            </div>
                        </li>
                        <div v-else class="icph-quarter">
                            <span class="icph-timeline-content">
                                {{ option.content || option }}
                            </span>
                        </div>
                    </template>
                </ul>
            </div>
        </div>
        <div class="icph-nav">
            <button class="icph-nav-left" :class="{ 'icph-has-more': has_prev && !is_empty }" @click="prev"><span>Past Events</span></button>
            <button class="icph-nav-right" :class="{ 'icph-has-more': has_next }" @click="next"><span>Future Events</span></button>
        </div>
        <p class="icph-info-text">*Events in the timeline that appear in pink have briefings linked to them and can be selected</p>
    </div>
</template>

<script>
    import Loader from  '@/components/Loader';
    import { Utils } from '@/helpers/';

    export default
    {
        name: 'timeline',
        components:
        {
            Loader,
        },
        props:
        {
            loading: Boolean,
            modelValue: String,
            dataset:
            {
                type: Array,
                required: true,
            }
        },
        emits: ['update:modelValue'],
        data()
        {
            return {
                offset_x: 0,
                is_centered: true,
                has_prev: false,
                has_next: true,
            };
        },
        computed:
        {
            value:
            {
                get()
                {
                    return this.modelValue;
                },
                set(value)
                {
                    this.$emit('update:modelValue', value);
                },
            },

            is_empty()
            {
                return !this.loading && !this.dataset.length;
            },
        },
        created()
        {
            this._per_step = 0;
            this._max_offset = -100;
        },
        mounted()
        {
            const viewport_width = this.$refs.viewport.clientWidth;
            const timeline_width = this.$refs['timeline-inner'].clientWidth;
            // const timeline_width = 1500;
            const per_step = viewport_width / timeline_width * 100;

            this._per_step = per_step;
            this._max_offset = -(100 - (per_step / 1.35));

            this.center_viewport();
        },
        watch:
        {
            dataset()
            {
                setTimeout(() =>
                {
                    const viewport_width = this.$refs.viewport.clientWidth;
                    const timeline_width = this.$refs['timeline-inner'].clientWidth;
                    // const timeline_width = 1500;
                    const per_step = viewport_width / timeline_width * 100;

                    this._per_step = per_step;
                    this._max_offset = -(100 - (per_step / 1.35));
                }, 300);
            },
        },
        methods:
        {
            on_swipe(ev)
            {
                if (ev === 'left')
                {
                    this.next();
                }
                else if (ev === 'right')
                {
                    this.prev();
                }
            },

            next()
            {
                this.offset_x -= this._per_step;
                this.offset_x = Math.max(this._max_offset, this.offset_x);

                this.has_next = (this.offset_x !== this._max_offset);
                this.has_prev = (this.offset_x !== 0);
                this.is_centered = false;
            },

            prev()
            {
                this.offset_x += this._per_step;
                this.offset_x = Math.min(0, this.offset_x);

                this.has_next = (this.offset_x !== this._max_offset);
                this.has_prev = (this.offset_x !== 0);
                this.is_centered = false;
            },

            select_item(option)
            {
                if (this.value !== option.value)
                {
                    this.value = option.value || 0;
                }
                else
                {
                    this.value = 0;
                }
            },

            center_viewport()
            {
                const timeline_width = this.$refs['timeline-inner'].clientWidth;

                setTimeout(() =>
                {
                    const center_el = document.querySelector('li.icph-current');

                    const offset = Utils.offset(center_el);

                    // console.log(per_step, this._max_offset);

                    this.offset_x -= Math.floor(offset.left / timeline_width * 100) - 10;
                    // console.log(offset.left / timeline_width * 100);

                    this.has_next = (this.offset_x !== this._max_offset);
                    this.has_prev = (this.offset_x !== 0);

                    this.is_centered = true;

                }, 400);
            },
        },
    }
</script>
