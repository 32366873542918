<template>
    <div
        class="icph-checkbox"
        :class="{
            'icph-disabled': disabled,
        }">
        <label class="icph-checkbox-inner" :for="unique_id">
            <input
                type="checkbox"
                :disabled="disabled"
                :id="unique_id"
                v-bind="$attrs"
                v-model="model"
                />
            <span class="icph-checkbox-checkmark"></span>
            <span class="icph-checkbox-label"><slot></slot></span>
        </label>
    </div>
    <slot name="error" v-if="error || $slots.error">
            <p class="icph-error">{{ error }}</p>
    </slot>
</template>

<script>
    export default
    {
        name: 'checkbox',
        props:
        {
            checked: [Array, Boolean],
            disabled: Boolean,
            error:
            {
                type: [String, Boolean],
                default: '',
            }
        },
        model:
        {
            prop: 'checked',
        },
        data()
        {
            return {
                unique_id: '',
                touched: false,
            };
        },
        computed:
        {
            model:
            {
                get()
                {
                    return this.checked;
                },
                set(value)
                {
                    if (!this.touched)
                    {
                        this.touched = true;
                    }

                    this.$emit('input', value);
                }
            },
        },
        created()
        {
            this.unique_id = Math
                .random()
                .toString(16)
                .slice(2);
        },
    }
</script>
