<template>
    <div class="icph-page-dashboard">
        <h1 class="icph-page-title">Latest Briefings</h1>
        <articles :loading="loading">
            <post
                v-for="(brief, key) in briefings"
                :key="key"
                :featured="key === 0"
                :image="brief.image_file"
                :title="brief.title"
                :date="brief.published_at"
                :excerpt="brief.summary"
                :link="'/briefing/' + brief.id"
                >
            </post>
        </articles>
        <h1 class="icph-page-title icph-map-title">Climate Diplomacy Snapshots</h1>
        <icph-map
            :loading="loading"
            :countries="countries"
            :infograms="map_infograms"
            :fully-expanded="true"
            v-model="map_country"
            >
        </icph-map>
        <teleport to="body">
            <router-view v-slot="{ Component }">
                <transition name="admin" mode="out-in">
                    <component :is="Component"></component>
                </transition>
            </router-view>
        </teleport>
    </div>
</template>

<script>
    import Articles from '@/components/Articles/Articles';
    import Post from '@/components/Articles/Post';
    import IcphMap from '@/components/ICPHMap';

    import { BriefingService, CountryService } from '@/services';

    export default
    {
        name: 'Dashboard',
        components:
        {
            Articles,
            Post,
            IcphMap,
        },
        data()
        {
            return {
                briefings: [],
                countries: [],
                map_infograms: {
                    'ID': 'https://infogram.com/indonesia_worldmap-1hnp27mmlppzy2g?live',     // Indonesia
                    'CN': 'https://infogram.com/china_worldmap-1h0r6rpp7kgll2e?live',     // China
                    'GB': 'https://infogram.com/uk_worldmap-1h0r6rpp7k8rw2e?live',     // UK
                    'CO': 'https://infogram.com/colombia_worldmap-1h0n25yyn9wkl6p?live',     // Colombia
                    'FR': 'https://infogram.com/france_worldmap-1h7v4pww1kkl86k?live',     // France
                    'JP': 'https://infogram.com/japan_worldmap-1hnp27mmlp17n2g?live',     // Japan
                    'TR': 'https://infogram.com/turkey_worldmap-1hxr4zxgj8wxo6y?live',     // Turkey
                    'KR': 'https://infogram.com/south-korea_worldmap-1h7v4pww1km586k?live',       // South Korea
                    'CL': 'https://infogram.com/chile_worldmap-1hnq4100d1wqp23?live',       // Chile
                    'ZA': 'https://infogram.com/south-africa_3-1hnq4100d1x8k23?live',     // South Africa
                    'CA': 'https://infogram.com/canada_worldmap-1h0r6rpp7kgww2e?live',       // Canada
                    'AR': 'https://infogram.com/argentina_worldmap-1h9j6qggnwd954g?live',     // Argentina
                    'TN': 'https://infogram.com/tunisia_worldmap-1hxr4zxlm88xo6y?live',       // Tunisia
                    'AU': 'https://infogram.com/australia_worldmap-1hzj4o35orx734p?live',     // Australia
                    'BR': 'https://infogram.com/brazil_worldmap-1h0n25yyn9wvz6p?live',     // Brazil
                    'US': 'https://infogram.com/united-states_worldmap-1hzj4o3lvp1l34p?live',
                    'NG': 'https://infogram.com/nigeria_worldmap-1h7z2l8113e1g6o?live',    // Nigeria

                    'EG': 'https://infogram.com/egypt_worldmap-1h7g6k0y9gp8o2o?live',     // Egypt
                    'IN': 'https://infogram.com/india_3-1hxj48pp988n52v?live',    // India
                    'MA': 'https://infogram.com/morocco_worldmap-1hd12yx9ep5jx6k?live',   // Morocco
                    'KE': 'https://infogram.com/kenya_worldmap-1h9j6qggnw8r54g?live',     // Kenya
                    'MX': 'https://infogram.com/mexico_worldmap-1h984woom8nnz6p?live',    // Mexico
                    'AE': 'https://infogram.com/uae_worldmap-1h7g6k0zzd0l02o?live',       // United Arab Emirates
                    'JO': 'https://infogram.com/jordan_worldmap-1h8n6m3nn1dwj4x?live',    // Jordan
                    'SA': 'https://infogram.com/saudi-arabia_worldmap-1h984woom8xmd6p?live',   // Saudi Arabia
                    'QA': 'https://infogram.com/qatar_worldmap-1hzj4o3llzmdo4p?live',         // Qatar
                    'LB': 'https://infogram.com/lebanon_worldmap-1h7g6k0zzv07o2o?live',       // Lebanon
                    'IT': 'https://infogram.com/italy_worldmap-1h984woom8nyd6p?live',         // Italy
                    'KW': 'https://infogram.com/kuwait_worldmap-1h9j6qgegg8r54g?live',        // Kuwait
                    'PH': 'https://infogram.com/philippines_worldmap-1h9j6qggnw80v4g?live',   // Philippines

                    'EU': 'https://infogram.com/europe_worldmap-1hnp27mmlppln2g?live',    // EU

                },
                // map_infograms: {
                //     'ID': '_/R0ZjlEFRtDw6WJRA1WMt',     // Indonesia
                //     'CN': '_/EIuPd4kwJpljQhNyKruz',     // China
                //     'GB': '_/4yCOo3qfUC4cPWXec11F',     // UK
                //     'CO': '_/HMfjvIm1jjqZwebdd6a9',     // Colombia
                //     'FR': '_/MJqTqSV78wDAY5eU2ALT',     // France
                //     'JP': '_/9uJIVPZkndTkqFC0MLQz',     // Japan
                //     'TR': '28821600-bd35-4456-8518-e7208d91b97d',     // Turkey
                //     'KR': '_/s4W6n6oNigOSPLohc2qE',       // South Korea
                //     'CL': '_/jwwAXU26eRf4ptx8CRbK',       // Chile
                //     'ZA': '_/m0Hb04bVf80YSZPcxbLu',     // South Africa
                //     'CA': '_/1N2tO5kCPpRJCiLUD7NM',       // Canada
                //     'AR': '_/viHReMwGZGyxTp6XqgKM',     // Argentina
                //     'TN': '_/MAhdUqC4g9csQsAXh157',       // Tunisia
                //     'AU': 'e153f1cc-27c6-4a40-b9d3-f17047ee8d7f',     // Australia
                //     'BR': '_/VEW8awj9L4zvwO2SL3ou',     // Brazil
                //     'US': 'e1d03de6-8330-4aed-a046-12e15c9540b2',    // United States
                //     'NG': '9546a465-6ece-4c75-abd2-3b429be9b92d',    // Nigeria
                // },
                loading: true,
            };
        },
        async created()
        {
            this.briefings = (await BriefingService.all({ 'per-page': 3, 'order-by': 'favorite,published_at' })).data.data;
            this.countries = (await CountryService.all()).data;

            this.countries.forEach((item) =>
            {
                item.value = item.id;
                item.label = item.name;
            });

            this.loading = false;
        },
    }
</script>
