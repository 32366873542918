import { ApiService } from './api-service';
import { TokenService } from './token-service';

class AuthenticationError extends Error
{
    constructor(error_code, message)
    {
        super(message)

        this.name = this.constructor.name;
        this.message = message;
        this.error_code = error_code;
    }
}

const AuthService =
{
    /**
     *  Login the user.
     *
     *  @returns access_token
     *  @throws AuthenticationError
     */
    login: async function (email, password)
    {
        try
        {
            await ApiService.get('/sanctum/csrf-cookie');
            const response = await ApiService.request({
                method: 'post',
                url: '/api/auth/login',
                data:
                {
                    email: email,
                    password: password,
                },
            });

            TokenService.save_token(response.data.token);
            ApiService.set_header();

            return response.data.token;
        }
        catch (ex)
        {
            throw new AuthenticationError(ex.status, ex.data.message);
        }
    },
    signup: async function (first_name,last_name, email, organisation, position, thematic_focus, country, geographic_region_focus, geographic_country_focus, political_access, institution_access, additional_comment, privacy_consent, attention)
    {
        try
        {
            await ApiService.get('/sanctum/csrf-cookie');
            const response = await ApiService.request({
                method: 'post',
                url: '/api/auth/signup',
                data:
                {
                    first_name: first_name,
                    last_name: last_name,
                    email: email,
                    organisation: organisation,
                    position: position,
                    thematic_focus: thematic_focus,
                    country: country,
                    geographic_region_focus: geographic_region_focus,
                    geographic_country_focus: geographic_country_focus,
                    political_access: political_access,
                    institution_access: institution_access,
                    additional_comment: additional_comment,
                    privacy_consent: privacy_consent,
                    attention: attention
                },
            });

            TokenService.save_token(response.data.token);
            ApiService.set_header();

            return response.data.token;
        }
        catch (ex)
        {
            if(ex.data && ex.status === 422) {
                throw new AuthenticationError(ex.status, ex.data.errors)
            }
            throw new AuthenticationError(ex.status, ex.data.message);
        }
    },
    logout()
    {
        TokenService.remove_token();
        ApiService.clear_header();
    },

    activate: function (data)
    {
        return ApiService.post('/api/auth/activate', data);
    },

    request_activate: function (data)
    {
        return ApiService.post('/api/auth/request-activate', data);
    },

    register: function (data)
    {
        return ApiService.post('/api/auth/register', data);
    },

    request_reset_password: async function (data)
    {
        await ApiService.get('/sanctum/csrf-cookie');
        return ApiService.post('/api/auth/password/forgot', data);
    },

    submit_reset_password: async function (data)
    {
        await ApiService.get('/sanctum/csrf-cookie');
        return ApiService.post('/api/auth/password/reset', data);
    },
};

export default AuthService;

export {AuthService, AuthenticationError};
