<template>
    <div class="icph-admin-list">
        <header class="icph-admin-list-header">
            <h1>List of Events &amp; Their Dates</h1>
            <router-link class="icph-button icph-color-primary" :to="{ name: 'EventsCreate' }">CREATE NEW EVENT</router-link>
            <panel
                class="icph-search-panel"
                :dark="true"
                >
                <base-input
                    :round="true"
                    v-model="search_term"
                    label="Search events..."
                    placeholder="Search"
                    @change="filter_events"
                    >
                </base-input>
                <Button class="icph-search-button" icon="icon-search" color="primary" @click="filter_events">
                    Search
                </Button>
            </panel>
        </header>
        <div class="icph-table" :class="{ 'icph-loading': loading }">
            <header>
                <div class="icph-table-col">#</div>
                <div class="icph-table-col icph-sortable">Title</div>
                <div class="icph-table-col">Date of the Event</div>
                <div class="icph-table-col">No. of Docs Attached</div>
                <div class="icph-table-col">Author</div>
                <div class="icph-table-col">Status</div>
                <div class="icph-table-col">Action</div>
            </header>
            <div class="icph-table-body">
                <loader />
                <p class="icph-table-empty" v-if="!has_events && !loading">There are no items to display.</p>
                <perfect-scrollbar :options="{ suppressScrollX: true }">
                    <div class="icph-table-row" v-for="(event, key) in filtered_events" :key="key">
                        <div class="icph-table-col">{{ key + 1 }}</div>
                        <div class="icph-table-col">{{ event.title }}</div>
                        <div class="icph-table-col">{{ event.start_at }}</div>
                        <div class="icph-table-col">{{ event.briefings.length || '0' }}</div>
                        <div class="icph-table-col">{{ event.author.name || '' }}</div>
                        <div class="icph-table-col icph-no-bg">
                            <SwitchButton
                                v-model:checked="event._published"
                                on-text="PUBLISHED"
                                off-text="DRAFT"
                                @input="set_publish(event)"
                                >
                            </SwitchButton>
                        </div>
                        <div class="icph-table-col icph-no-bg">
                            <router-link class="icph-button icph-color-primary icph-button-edit" :to="{ name: 'EventsEdit', params: { id: event.id } }"></router-link>
                            <button
                                class="icph-button icph-color-primary icph-button-remove"
                                @click="remove_event(event, key)"
                                >
                            </button>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</template>

<script>
    import SwitchButton from '@/components/Buttons/SwitchButton';
    import Button from '@/components/Buttons/Button';
    import Panel from '@/components/Panels/Panel';
    import BaseInput from '@/components/Inputs/BaseInput';

    import Loader from '@/components/Loader';

    import { EventService } from '@/services';

    export default
    {
        name: 'EventsList',
        components:
        {
            SwitchButton,
            Button,
            Panel,
            BaseInput,
            Loader,
        },
        data()
        {
            return {
                loading: false,
                has_events: false,
                events: [],
                filtered_events: [],
            };
        },
        async mounted()
        {
            this.loading = true;

            this.events = (await EventService.all({ all: 1, 'per-page': -1 })).data.data;

            this.events.forEach((event) => { event._published = !!event.published_at });

            this.filtered_events = this.events;

            if (this.events && this.events.length)
            {
                this.has_events = true;
            }

            this.loading = false;
        },
        methods:
        {
            async set_publish(event)
            {
                if (event._published)
                {
                    event.published_at = (await EventService.publish(event.id)).data.published_at;
                }
                else
                {
                    await EventService.unpublish(event.id);
                    event.published_at = '';
                }
            },

            async remove_event(event, index)
            {
                if (confirm('Are you sure you want to remove this event?'))
                {
                    await EventService.remove(event.id);

                    this.events = this.events.splice(index, 1);
                }
            },

            filter_events()
            {
                const search_term = this.search_term.toLowerCase();

                this.filtered_events = this.events.filter((event) =>
                {
                    return (event.title).toLowerCase().includes(search_term);
                });

                this.has_events = this.filtered_events && this.filtered_events.length;
            }
        }
    }
</script>
