<template>
    <div class="icph-admin-list">
        <header class="icph-admin-list-header">
            <h1>List of Registered Users</h1>
            <router-link class="icph-button icph-color-primary" :to="{ name: 'UsersCreate' }" v-if="logged_user.role === 'super-admin'">CREATE NEW USER</router-link>
            <panel
                class="icph-search-panel"
                :dark="true"
                >
                <base-input
                    :round="true"
                    v-model="search_term"
                    label="Search users..."
                    placeholder="Search"
                    @change="filter_users"
                    >
                </base-input>
                <Button class="icph-search-button" icon="icon-search" color="primary" @click="filter_users">
                    Search
                </Button>
            </panel>
        </header>
        <div class="icph-table" :class="{ 'icph-loading': loading }">
            <header>
                <div class="icph-table-col">#</div>
                <div class="icph-table-col">Name</div>
                <div class="icph-table-col">Email</div>
                <div class="icph-table-col">Role</div>
                <div class="icph-table-col">Last modified time</div>
                <div class="icph-table-col" v-if="logged_user.role === 'super-admin'">Action</div>
            </header>
            <div class="icph-table-body">
                <loader />
                <p class="icph-table-empty" v-if="!has_briefings && !loading">There are no items to display.</p>
                <perfect-scrollbar :options="{ suppressScrollX: true }">
                    <div class="icph-table-row" v-for="(user, key) in filtered_users" :key="key">
                        <div class="icph-table-col">{{ key + 1 }}</div>
                        <div class="icph-table-col">{{ user.name }}</div>
                        <div class="icph-table-col">{{ user.email }}</div>
                        <div class="icph-table-col">{{ user.role }}</div>
                        <div class="icph-table-col">{{ user.updated_at }}</div>
                        <div class="icph-table-col icph-no-bg" v-if="logged_user.role === 'super-admin'">
                            <router-link
                                class="icph-button icph-color-primary icph-button-edit"
                                :to="{ name: 'UsersEdit', params: { id: user.id } }"
                                ></router-link>
                            <button
                                class="icph-button icph-color-primary icph-button-remove"
                                @click="remove_user(user, key)"
                                >
                            </button>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from '@/components/Buttons/Button';
    import Panel from '@/components/Panels/Panel';
    import BaseInput from '@/components/Inputs/BaseInput';
    import Loader from '@/components/Loader';

    import { UserService } from '@/services';

    export default
    {
        name: 'UsersList',
        components:
        {
            Button,
            Panel,
            BaseInput,
            Loader,
        },
        data()
        {
            return {
                loading: false,
                has_briefings: false,
                users: [],
                search_term: '',
                filtered_users: [],
            };
        },
        computed:
        {
            logged_user()
            {
                return this.$store.getters['Auth/user'];
            },
        },
        async mounted()
        {
            this.loading = true;

            this.users = (await UserService.all({ 'per-page': -1 })).data.data;

            if (this.users && this.users.length)
            {
                this.has_briefings = true;
            }

            this.filtered_users = this.users;

            this.loading = false;
        },
        methods:
        {
            filter_users()
            {
                this.filtered_users = this.users.filter((user) =>
                {
                    return (user.name + ' ' + user.email + ' ' + user.role).includes(this.search_term);
                });

                this.has_briefings = this.filtered_users && this.filtered_users.length;
            },

            async remove_user(user, index)
            {
                if (confirm('Are you sure you want to remove this user?'))
                {
                    await UserService.remove(user.id);

                    this.users = this.users.splice(index, 1);
                }
            }
        },
    }
</script>
