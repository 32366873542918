<template>
    <div
        :class="{
            'icph-round': round,
            'icph-focused': focused,
            'icph-error': error,
            'icph-success': !error && touched,
            'icph-expanded': expanded,
            'icph-dark': dark,
            'has-label': label,
            'has-icon': has_icon,
            'icph-mode-tags': mode === 'tags',
        }"
        class="icph-input icph-dropdown"
        >
        <slot name="label">
            <label v-if="label">{{ label }} <span class="required-symbol" v-if="required">*</span></label>
        </slot>
        <div class="icph-input-inner">
            <Multiselect
                :placeholder="placeholder"
                v-bind="$attrs"
                v-model="value"
                :mode="mode"
                :searchable="true"
                :options="options"
            >
                <template v-slot:caret>
                    <i class="icph-dropdown-icon icon-chevron-down"></i>
                </template>
            </Multiselect>
        </div>
        <slot name="error" v-if="error || $slots.error">
            <p class="icph-error">{{ error }}</p>
        </slot>
    </div>
</template>

<script>
    import Multiselect from '@vueform/multiselect'

    export default
    {
        name: 'dropdown',
        inheritAttrs: false,
        components:
        {
            Multiselect,
        },
        props:
        {
            label: String,
            required: Boolean,
            round: Boolean,
            dark: Boolean,
            placeholder: String,
            modelValue: {required: !1},
            mode: String,
            options:
            {
                type: Array,
                required: true,
                default: () => [],
            },
            error:
            {
                type: String,
                default: ''
            },
            iconLeft: String,
        },
        emits: ['update:modelValue'],
        data()
        {
            return {
                selected: false,
                focused: false,
                touched: false,
                expanded: false,
            };
        },
        computed:
        {
            value:
            {
                get()
                {
                    return this.modelValue;
                },
                set(value)
                {
                    this.$emit('update:modelValue', value);
                },
            },

            has_icon()
            {
                const { iconLeft } = this.$slots;

                return (
                    iconLeft !== undefined ||
                    this.iconLeft !== undefined
                );
            },
        },
        methods:
        {
        }
    }
</script>
