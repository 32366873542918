<template>
    <div
        class="icph-page-login icph-page-activate"
        :class="{
            'icph-loading': loading
        }"
        >
        <panel>
            <h1 class="icph-lineunder">Reset passwod</h1>
            <loader />
            <form @submit.prevent="reset">
                <base-input
                    label="Password"
                    type="password"
                    v-model="model.password"
                    :error="get_errors('password')"
                    >
                </base-input>
                <base-input
                    label="Confirm Password"
                    type="password"
                    v-model="model.password_confirmation"
                    :error="get_errors('password_confirmation')"
                    >
                </base-input>
                <Button type="submit">Reset</Button>
                <p>If you're having any issues please contact <a href="mailto:info@icphub.org">info@icphub.org</a></p>
            </form>
        </panel>
    </div>
</template>

<script>
    import Panel from '@/components/Panels/Panel';
    import BaseInput from '@/components/Inputs/BaseInput';
    import Button from '@/components/Buttons/Button';
    import Loader from '@/components/Loader';

    import { AuthService } from '@/services';

    import useVuelidate from '@vuelidate/core';
    import { helpers, required, email, minLength } from '@vuelidate/validators';

    export default
    {
        name: 'ResetPassword',
        components:
        {
            Panel,
            BaseInput,
            Button,
            Loader,
        },
        setup()
        {
            return { v$: useVuelidate() };
        },
        data()
        {
            return {
                submitted: false,
                loading: false,
                error: '',
                model:
                {
                    email: this.$route.params.email,
                    token: this.$route.params.token,
                    password: '',
                    password_confirmation: '',
                },
            };
        },
        validations()
        {
            return {
                model:
                {
                    email: { required, email },
                    password: { required, minLength: minLength(6) },
                    password_confirmation: {
                        sameAsPass: helpers.withMessage('Confirm password should be the same as password', () => this.model.password === this.model.password_confirmation)
                    },
                },
            };
        },
        methods:
        {
            async reset()
            {
                const is_valid = await this.v$.$validate();

                if (!is_valid)
                {
                    return ;
                }

                this.loading = true

                await AuthService.submit_reset_password(this.model);

                await this.$store.dispatch('Auth/login',
                {
                    email: this.model.email,
                    password: this.model.password,
                });

                this.loading = false;
            },

            get_errors(field, message)
            {
                const $err = this.v$.model[field];

                if ($err.$error)
                {
                    return message || $err.$errors.map((err) => err.$message).join(' - ');
                }

                return false;
            }
        },
    }
</script>
