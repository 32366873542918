import { ApiService } from './api-service';

const CountryService =
{
    all()
    {
        return ApiService.get(`/api/countries`);
    },

    get(id)
    {
        return ApiService.get(`/api/countries/${id}`);
    },

};

export { CountryService };
