<template>
    <div
        :class="{
            'icph-round': round,
            'icph-focused': focused,
            'icph-error': error,
            'icph-success': !error && touched,
            'icph-dark': dark,
            'has-label': label,
            'has-icon': has_icon,
        }"
        class="icph-input icph-multiselect"
        >
        <slot name="label">
            <label v-if="label">{{ label }} <span class="required-symbol" v-if="required">*</span></label>
        </slot>
        <div class="icph-input-inner">
            <slot name="iconLeft">
                <i class="icph-input-icon-left" :class="iconLeft" v-if="iconLeft"></i>
            </slot>
            <span
                class="icph-caption"
                :class="{
                    'icph-placeholder': !(this.value || this.selected)
                }">{{ caption }}</span>
            <i class="icph-multiselect-icon icon-chevron-down"></i>
            <div class="icph-multiselect-menu">
                <perfect-scrollbar :options="{ suppressScrollX: true }">
                    <ul>
                        <slot>
                            <li
                                @click.stop="select_option(option)"
                                v-for="(option, index) in computed_options"
                                :class="{
                                    'icph-checked': option._checked,
                                }"
                                :key="index"
                                >
                                {{ option.name || option.id || option }}
                            </li>
                        </slot>
                    </ul>
                </perfect-scrollbar>
            </div>
        </div>
        <slot name="error" v-if="error || $slots.error">
            <p class="icph-error">{{ error }}</p>
        </slot>
    </div>
</template>

<script>
    export default
    {
        name: 'multi-select',
        inheritAttrs: false,
        props:
        {
            label: String,
            required: Boolean,
            round: Boolean,
            dark: Boolean,
            placeholder: String,
            modelValue: [Array, String, Number, Object],
            options:
            {
                type: Array,
                required: true,
                default: () => [],
            },
            error:
            {
                type: String,
                default: ''
            },
            iconLeft: String,
        },
        emits: ['update:modelValue', 'change'],
        data()
        {
            return {
                computed_options: {},
                selected: false,
                focused: false,
                touched: false
            };
        },
        computed:
        {
            value:
            {
                get()
                {
                    return this.modelValue;
                },
                set(value)
                {
                    this.$emit('update:modelValue', value);
                },
            },

            caption()
            {
                const selected = this.value || {};

                return selected.name || selected.id ||  this.placeholder || '';
            },
            has_icon()
            {
                const { iconLeft } = this.$slots;

                return (
                    iconLeft !== undefined ||
                    this.iconLeft !== undefined
                );
            },
            listeners()
            {
                return {
                    input: this.on_input,
                    blur: this.on_blur,
                    focus: this.on_focus,
                };
            },
        },
        watch:
        {
            options(val)
            {
                this.computed_options = val.map((option) =>
                {
                    if (typeof option === 'string' || option instanceof String)
                    {
                        return {
                            _checked: ~this.value.indexOf(option),
                            name: option
                        };
                    } else if (typeof option === 'object' || option instanceof Object) {
                        return {
                            _checked: option.value !== undefined ? ~this.value.indexOf(option.value) : false,
                            name: option.label ?? option.name
                        }
                    }

                    return option;
                });

                if (Array.isArray(this.modelValue))
                {
                    this.computed_options.forEach((option) =>
                    {
                        option._checked = ~this.modelValue.map(value => value.trim()).indexOf(option.name.trim());
                    });
                }
            },
        },
        mounted()
        {
            this.computed_options = this.options.map((option) =>
            {
                if (typeof option === 'string' || option instanceof String)
                {
                    return {
                        _checked: ~this.value.indexOf(option),
                        name: option
                    };
                } else if (typeof option === 'object' || option instanceof Object) {
                    return {
                        _checked: option.value !== undefined ? ~this.value.indexOf(option.value) : false,
                        name: option.label != null ? option.label : option.name
                    }
                }

                return option;
            });

            if (Array.isArray(this.modelValue))
            {
                this.computed_options.forEach((option) =>
                {
                    option._checked = ~this.modelValue.map(value => value.trim()).indexOf(option.name.trim());
                });
            }
        },
        methods:
        {
            on_input(ev)
            {
                if (!this.touched)
                {
                    this.touched = true;
                }

                this.$emit('input', ev.target.value);
            },
            on_focus()
            {
                this.focused = true;
            },
            on_blur()
            {
                this.focused = false;
            },
            select_option(option)
            {
                option._checked = !option._checked;
                this.selected = option;
                // this.value = option;

                this.value = this.computed_options.filter((item) => item._checked).map((item) => item.name);
            }
        }
    }
</script>
