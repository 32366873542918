<template>
    <div class="icph-page-calendar">
        <h1 class="icph-page-title">Calendar</h1>
        <div class="icph-page-inner">
            <calendar></calendar>
            <div class="icph-upcoming-calls">
                <h3>Upcoming calls</h3>
                <div class="icph-upcoming-calls-inner">
                    <perfect-scrollbar>
                        <div class="icph-upcoming-call" v-for="(ev, key) in gevents" :key="key">
                            <h2>{{ ev.customData.content }}</h2>
                            <ul class="icph-upcoming-call-info">
                                <!-- <li data-label="Type of event">Multilateral</li> -->
                                <li data-label="Date">{{ ev.formated_date }}</li>
                                <li data-label="Time">{{ ev.formated_time }}</li>
                                <!-- <li data-label="Issue">Finance</li> -->
                            </ul>
                        </div>
                    </perfect-scrollbar>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Calendar from '@/components/Calendar';

    import { CalendarService } from '@/services';

    export default
    {
        name: 'CalendarPage',
        components:
        {
            Calendar,
        },
        data()
        {
            return {
                gevents: [],
            };
        },
        async mounted()
        {
            this.gevents = (await CalendarService.all()).data;
        },
    }
</script>
