<template>
    <div
        style="width: 100%; display: flex; justify-content: center;"
        >
        <panel class="icph-signup-thankyou">
            <h1 class="icph-lineunder">Request Submitted</h1>
            <p class="icph-signup-form-tagline">
                Thank you for your interest in joining the ICPH Briefings & Strategies list! We appreciate your request and will get back to you as soon as possible.
            </p>
        </panel>
    </div>
</template>

<script>
    import Panel from '@/components/Panels/Panel';


    export default
    {
        name: 'SignUpThankyou',
        components:
        {
            Panel,
        },
        data()
        {
            return {
         
                model:
                {

                },
            };
        },
        computed:
        {
        },
        methods:
        {
        
        },
        async mounted()
        {

        },
    }
</script>
