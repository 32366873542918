import { ApiService } from './api-service';

const EventService =
{
    all(params)
    {
        return ApiService.get('/api/events', params);
    },

    get(id)
    {
        return ApiService.get(`/api/events/${id}`);
    },

    update(id, data)
    {
        return ApiService.put(`/api/events/${id}`, data);
    },

    publish(id)
    {
        return ApiService.put(`/api/events/${id}/publish`);
    },

    unpublish(id)
    {
        return ApiService.put(`/api/events/${id}/unpublish`);
    },

    create(data)
    {
        return ApiService.post('/api/events', data);
    },

    remove(id)
    {
        return ApiService.delete(`/api/events/${id}`);
    },
};

export {EventService};
