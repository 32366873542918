<template>
    <div class="admin-menu" :class="{'menu-expanded': menu_expanded}">
        <nav class="menu-nav">
            <router-link to="/admin/documents/create" class="icph-menu-item icph-item-create-document" @click="menu_expanded = false"><span>Create document</span></router-link>
            <router-link to="/admin/documents" class="icph-menu-item icph-item-documents" @click="menu_expanded = false"><span>Documents</span></router-link>
            <router-link to="/admin/users" class="icph-menu-item icph-item-users" @click="menu_expanded = false"><span>Users</span></router-link>
            <router-link to="/admin/events" class="icph-menu-item icph-item-events" @click="menu_expanded = false"><span>Events</span></router-link>
            <router-link to="/admin/topics" class="icph-menu-item icph-item-topics" @click="menu_expanded = false"><span>Topics/Tags</span></router-link>
        </nav>
        <button class="admin-menu-button" @click="toggle_menu()">
            <svg width="110.448" height="110.448" viewBox="0 0 110.448 110.448">
                <circle cx="8.284" cy="8.284" r="8.284" transform="translate(46.94 46.94)" fill="#2e656b"/>
                <path d="M66.706,49.928a10.982,10.982,0,0,1-5.476-9.5v-.09a10.978,10.978,0,0,1,5.476-9.493,1.717,1.717,0,0,0,.808-1.049,1.738,1.738,0,0,0-.174-1.316l-3.793-6.567a1.727,1.727,0,0,0-2.359-.634l0,0-.255.143A10.452,10.452,0,0,1,50.4,21.4l-.446-.252a10.433,10.433,0,0,1-5.293-9.108v-.3a1.73,1.73,0,0,0-1.731-1.731H35.35a1.73,1.73,0,0,0-1.731,1.731h0v.3a10.427,10.427,0,0,1-5.293,9.114l-.443.255a10.452,10.452,0,0,1-10.528.028l-.261-.149a1.727,1.727,0,0,0-2.359.634h0l-3.793,6.567a1.733,1.733,0,0,0-.174,1.313,1.715,1.715,0,0,0,.808,1.052,11.041,11.041,0,0,1,0,19.078,1.733,1.733,0,0,0-.64,2.362l0,.006,3.647,6.309a2.03,2.03,0,0,0,2.76.741,10.538,10.538,0,0,1,5.246-1.408,10.311,10.311,0,0,1,5.271,1.439l.446.255a10.436,10.436,0,0,1,5.31,9.1,2.027,2.027,0,0,0,2.028,2.025h6.988a2.027,2.027,0,0,0,2.028-2.025,10.427,10.427,0,0,1,5.293-9.114l.468-.275a10.438,10.438,0,0,1,10.491-.017,2.03,2.03,0,0,0,2.76-.741l3.644-6.306a1.729,1.729,0,0,0-.631-2.365l0,0ZM39.139,54.195A13.806,13.806,0,1,1,52.946,40.388,13.8,13.8,0,0,1,39.139,54.195Z" transform="translate(16.083 14.835)" fill="#2e656b"/>
                <path d="M56.38,1.157A55.224,55.224,0,1,0,111.6,56.38,55.224,55.224,0,0,0,56.38,1.157ZM89.363,71.048l-3.644,6.32a7.556,7.556,0,0,1-10.309,2.76,4.915,4.915,0,0,0-4.951,0l-.553.32a4.908,4.908,0,0,0-2.48,4.281,7.559,7.559,0,0,1-7.552,7.549H52.887a7.559,7.559,0,0,1-7.552-7.549,4.9,4.9,0,0,0-2.471-4.292l-.553-.306a4.911,4.911,0,0,0-4.971,0,7.56,7.56,0,0,1-10.312-2.76L23.4,71.048a7.251,7.251,0,0,1,2.651-9.905l.006,0a5.526,5.526,0,0,0,0-9.515A7.263,7.263,0,0,1,23.4,41.717l3.793-6.567A7.263,7.263,0,0,1,37.1,32.494l.258.151a4.909,4.909,0,0,0,4.948,0l.553-.32a4.9,4.9,0,0,0,2.474-4.289v-.3a7.266,7.266,0,0,1,7.254-7.254h7.582a7.266,7.266,0,0,1,7.254,7.254v.3A4.906,4.906,0,0,0,69.9,32.325l.553.323a4.909,4.909,0,0,0,4.948,0l.252-.146a7.259,7.259,0,0,1,9.913,2.654l3.793,6.567A7.263,7.263,0,0,1,86.7,51.63a5.443,5.443,0,0,0-2.715,4.7v.1A5.45,5.45,0,0,0,86.7,61.134a7.251,7.251,0,0,1,2.659,9.9l0,.006Z" transform="translate(-1.157 -1.157)" fill="#2e656b"/>
            </svg>
        </button>
    </div>
</template>

<script>
    export default
    {
        name: 'AdminMenu',
        data()
        {
            return {
                menu_expanded: false,
            };
        },
        methods:
        {
            toggle_menu()
            {
                this.menu_expanded = !this.menu_expanded;
            },
        },
    }
</script>
