
const state = {
    searchTerm: null,
};

const mutations =
{
    setSearchTerm(state, data) {
        state.searchTerm = data;
    },

    clearSearchTerm(state) {
        state.searchTerm = null;
    }
};

export const BriefingSearch = {
    namespaced: true,
    state,
    mutations
};
