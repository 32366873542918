<template>
    <div class="icph-page-briefing"
        :class="{
            'has-topics': has_topics,
        }"
    >
        <panel
            class="icph-briefing-panel"
            >
            <div class="icph-top-action-bar">
                <h1 class="icph-page-title">{{ brief.type || 'Briefings' }}</h1>
                <span class="icph-briefing-date" v-if="brief.published_at">{{ brief.published_at.split(' ')[0] }} <span>{{ brief.published_at.split(' ')[1] }}</span></span>
                <!-- <Button
                    class="icph-expand-view"
                    icon="icon-shrink"
                    :round="true"
                    @click="expand_briefing = true"
                    >
                    EXPAND
                </Button> -->
                <Button
                    class="icph-download"
                    icon-right="icon-download"
                    color="primary"
                    :round="true"
                    @click="download_pdf"
                    >
                    DOWNLOAD
                </Button>
            </div>
            <div class="icph-briefing-content">
                <h1 :data-date="brief.published_at">{{ brief.title }}</h1>
                <div v-html="brief.content"></div>
            </div>
            <div class="icph-bottom-action-bar">
                <label>Will you use this briefing?</label>
                <radiobox v-model="rate" value="yes" name="brf-rate" @input="update_rate">YES</radiobox>
                <radiobox v-model="rate" value="no" name="brf-rate" @input="update_rate">NO</radiobox>
            </div>
            <tags class="icph-topics" label="Topic" :tags="brief.topics"></tags>
            <Button
                class="icph-get-in-touch"
                icon-right="icon-get-in-touch"
                color="secondary"
                :round="true"
                @click="this.$router.push({ name: 'Contact', params: { message: 'I\'m getting in touch regarding ' + brief.title} })"
                >
                GET IN TOUCH
            </Button>
        </panel>
        <h1 class="icph-page-title icph-title-related">Related Briefings</h1>
        <articles :loading="loading_related">
            <post
                v-for="(brief, key) in briefings"
                :key="key"
                :no-image="true"
                :image="brief.image_file"
                :title="brief.title"
                :date="brief.published_at"
                :excerpt="brief.summary"
                :link="'/briefing/' + brief.id"
                >
            </post>
        </articles>

    </div>
</template>

<script>
    import Panel from '@/components/Panels/Panel';
    import Button from '@/components/Buttons/Button';
    import Radiobox from '@/components/Inputs/Radiobox';
    import Tags from '@/components/Tags';
    import Articles from '@/components/Articles/Articles';
    import Post from '@/components/Articles/Post';

    import { BriefingService } from '@/services';

    export default
    {
        name: 'Briefing',
        components:
        {
            Panel,
            Button,
            Radiobox,
            Tags,
            Articles,
            Post,
        },
        data()
        {
            return {
                briefings: [],
                brief: {},
                rate: '',
                expand_briefing: false,
                loading_related: false,
            };
        },
        computed:
        {
            has_topics()
            {
                return this.brief.topics && this.brief.topics.length;
            },
        },
        async beforeRouteUpdate(to)
        {
            if (window.location.hash === '#' || window.location.hash === '')
            {
                document.querySelector('#app > .ps').scrollTo(0, 0, 'smooth');
            }

            await this.get_brief(to.params.id);
        },
        async mounted()
        {
            const ps_el = document.querySelector('#app > .ps');

            // const tl = layout_content.getBoundingClientRect().top;

            ps_el.scrollTo(0, 0, 'smooth');
            await this.get_brief(this.$route.params.id);
            const div_bounds = document.querySelector('.icph-briefing-content > div').getBoundingClientRect();

            const h1s = document.querySelectorAll('h1[id]');

            ps_el.addEventListener('scroll', (ev) =>
            {
                const scroll_top = ev.target.scrollTop;
                const summary_el = document.querySelector('.icph-content-summary');

                if(summary_el) {
                    if (scroll_top > div_bounds.top)
                    {
                        summary_el.classList.add('icph-summary-float');
                    }
                    else
                    {
                        summary_el.classList.remove('icph-summary-float');
                    }

                    summary_el.style.top = Math.min(div_bounds.height, (scroll_top - div_bounds.top + (45))) + 'px';

                    // const h1s = document.querySelectorAll('h1[id]');

                    for (let i = h1s.length - 1; i >= 0; i--)
                    {
                        if (scroll_top - div_bounds.top > h1s[i].offsetTop - 200)
                        {
                            [...summary_el.children].forEach((el) => el.classList.remove('selected'));
                            summary_el.querySelector('[href="#' + h1s[i].id + '"').classList.add('selected');
                            break;
                        }
                    }
                }

            }, false);

        },
        methods:
        {
            async get_brief(id)
            {
                this.loading_related = true;

                const brief = (await BriefingService.get(id)).data;
                this.briefings = (await BriefingService.all({ related: id, 'per-page': 3 })).data.data;

                // brief.popup_content = this.add_content_summary(brief.content, 'popup-');
                brief.content = this.add_content_summary(brief.content, 'main-');

                this.brief = brief;

                if (brief.rating !== null)
                {
                    this.rate = brief.rating ? 'yes' : 'no';
                }

                this.loading_related = false;
            },

            add_content_summary(content, prefix)
            {
                let el = document.createElement('html');

                el.innerHTML = content;

                let summary_links = Array.from(el.querySelectorAll('h1')).filter((item) => item.innerText.trim() !== '').map((item) =>
                {
                    item.id = prefix + item.innerText.trim().replace(/^[^a-z]+|[^\w:.-]+/gi, '').toLowerCase();

                    return '<a href="#' + item.id + '">' + item.innerText.trim() + '</a>';
                });

                return '<div class="icph-content-summary">' + summary_links.join('&nbsp;|&nbsp;') + '</div>' + el.innerHTML;
            },

            download_pdf()
            {
                window.open(process.env.VUE_APP_BACKEND_URL + 'api/files/' + this.brief.pdf_file.id, '_blank');
            },

            async update_rate()
            {
                (await BriefingService.rate(this.brief.id, { 'rating': this.rate === 'yes' ? 1 : 0}));
            }
        },
    }
</script>
