<template>
    <div class="icph-admin-list">
        <header class="icph-admin-list-header">
            <h1>List of Topics</h1>
            <router-link class="icph-button icph-color-primary" :to="{ name: 'TopicsCreate' }">CREATE NEW TOPIC</router-link>
            <panel
                class="icph-search-panel"
                :dark="true"
                >
                <base-input
                    :round="true"
                    v-model="search_term"
                    label="Search topics..."
                    placeholder="Search"
                    @change="filter_topics"
                    >
                </base-input>
                <Button class="icph-search-button" icon="icon-search" color="primary" @click="filter_topics">
                    Search
                </Button>
            </panel>
        </header>
        <div class="icph-table" :class="{ 'icph-loading': loading }">
            <header>
                <div class="icph-table-col">#</div>
                <div class="icph-table-col icph-sortable">Title</div>
                <div class="icph-table-col">Action</div>
            </header>
            <div class="icph-table-body">
                <loader />
                <p class="icph-table-empty" v-if="!has_topics && !loading">There are no items to display.</p>
                <perfect-scrollbar :options="{ suppressScrollX: true }">
                    <div class="icph-table-row" v-for="(topic, key) in filtered_topics" :key="key">
                        <div class="icph-table-col">{{ key + 1 }}</div>
                        <div class="icph-table-col">{{ topic.name }}</div>
                        <div class="icph-table-col icph-no-bg">
                            <router-link class="icph-button icph-color-primary icph-button-edit" :to="{ name: 'TopicsEdit', params: { id: topic.id } }"></router-link>
                            <button
                                class="icph-button icph-color-primary icph-button-remove"
                                @click="remove_topic(topic, key)"
                                >
                            </button>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from '@/components/Buttons/Button';
    import Panel from '@/components/Panels/Panel';
    import BaseInput from '@/components/Inputs/BaseInput';

    import Loader from '@/components/Loader';

    import { TopicService } from '@/services';

    export default
    {
        name: 'TopicsList',
        components:
        {
            Button,
            Panel,
            BaseInput,
            Loader,
        },
        data()
        {
            return {
                loading: false,
                has_topics: false,
                topics: [],
                filtered_topics: [],
            };
        },
        async mounted()
        {
            this.loading = true;
            this.topics = (await TopicService.all({ all: 1, 'per-page': -1 })).data;

            this.filtered_topics = this.topics;

            if (this.topics && this.topics.length)
            {
                this.has_topics = true;
            }

            this.loading = false;
        },
        methods:
        {
            async remove_topic(topic, index)
            {
                if (confirm('Are you sure you want to remove this topic?'))
                {
                    TopicService.remove(topic.id).then(() =>
                    {
                        this.topics = this.topics.splice(index, 1);
                        this.$toast.success('Topic was successfully deleted!');
                    },
                    (error) =>
                    {
                        if (error.data.message)
                        {
                            this.$toast.error(error.data.message);
                        }
                        else
                        {
                            this.$toast.error('Something went wrong. Please try again!');
                        }
                    });
                }
            },

            filter_topics()
            {
                const search_term = this.search_term.toLowerCase();

                this.filtered_topics = this.topics.filter((topic) =>
                {
                    return (topic.name).toLowerCase().includes(search_term);
                });

                this.has_topics = this.filtered_topics && this.filtered_topics.length;
            }
        }
    }
</script>
