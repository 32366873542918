<template>
    <div class="icph-lazy-img">
        <div
            v-if="data_url"
            :style="{ background }"
            class="icph-img-placeholder"
            >
            <img :src="placeholder || data_url" alt="" v-bind="$attrs" />
        </div>
        <img
            :src="data_url"
            :alt="$attrs.alt || ''"
            v-bind="$attrs"
            class="icph-img-img"
        />
    </div>
</template>
<script>
    export default
    {
        inheritAttrs: false,
        name: 'lazy-img',
        props:
        {
            src:
            {
                type: String,
                required: true,
            },
            placeholder: String,
            background: String,
        },
        computed:
        {
            data_url()
            {
                const { width, height } = this.$attrs;

                if (!width || !height)
                {
                    return '';
                }

                const w = 100;
                const canvas = document.createElement('canvas');
                canvas.width = w;
                canvas.height = (height / width) * w;

                return canvas.toDataURL();
            },
        },
        mounted()
        {
            const { src, srcset, $el } = this;
            let time_out;

            const observer = new IntersectionObserver(([entry]) =>
            {
                const img = $el.querySelector('.icph-img-img');
                const placeholder = $el.querySelector('.icph-img-placeholder');

                img.onload = function()
                {
                    delete img.onload;
                    $el.classList.add('icph-img-loaded');

                    if (placeholder)
                    {
                        time_out = setTimeout(() =>
                        {
                            placeholder.remove();
                        }, 300);
                    }
                };

                if (entry.isIntersecting)
                {
                    // Element is in viewport
                    if (srcset)
                    {
                        img.srcset = srcset;
                    }

                    img.src = src;
                    observer.disconnect();
                }
            });

            observer.observe($el);

            console.log(time_out);

            // this.$once("hook:beforeDestroy", () =>
            // {
            //     observer.disconnect();
            //
            //     if (time_out)
            //     {
            //         clearTimeout(time_out);
            //     }
            // });
        },
    }
</script>

<style>
.icph-img
{
    display: inline-block;
    position: relative;
}

.icph-img-placeholder
{
    position: absolute;
    overflow: hidden;
}

.icph-img-placeholder img
{
    transform: scale(1.05);
    filter: blur(10px);
}

.icph-img-img
{
    opacity: 0;
    transition: opacity 300ms ease;
}

.icph-img-loaded .icph-img-img
{
    opacity: 1;
}
</style>
