<template>
    <div class="icph-calendar" ref="icph-calendar">
        <v-calendar
            :masks="masks"
            :attributes="attributes"
            :first-day-of-week="2"
            :timezone="timezone"
            disable-page-swipe
            is-expanded
            >
            <template v-slot:header="{ year, prevMonthComps, nextMonthComps, yearLabel, monthLabel }">
                <div class="icph-calendar-header">
                    <span class="icph-calendar-prev-month">
                        <span v-if="prevMonthComps.year !== year">{{ prevMonthComps.year }}<br /></span>
                        <b>{{ format_month(prevMonthComps) }}</b>
                    </span>
                    <span class="icph-calendar-current">{{ yearLabel }}<br /><b>{{ monthLabel }}</b></span>
                    <span class="icph-calendar-next-month">
                        <span v-if="nextMonthComps.year !== year">{{ nextMonthComps.year }}<br /></span>
                        <b>{{ format_month(nextMonthComps) }}</b>
                    </span>
                </div>
            </template>
            <template v-slot:header-left-button>

            </template>
            <template v-slot:day-content="{ day, attributes }">
                <div class="icph-calendar-day" :data-label="day.day">
                    <p
                        v-for="(attr, index) in attributes"
                        :class="attr.classes"
                        :key="index"
                        >
                        {{ attr.customData.content }}
                    </p>
                    <button class="icph-calendar-add-to" @click="add_event_to_calendar(attributes)">Add to Outlook/Google Calendar <i class="icph-plus"></i></button>
                </div>
            </template>
        </v-calendar>
    </div>
</template>

<script>
    import { CalendarService } from '@/services';

    export default
    {
        name: 'calendar',
        props:
        {
        },
        data()
        {
            return {
                timezone: '',
                masks:
                {
                    weekdays: 'WWW',
                },
                attributes: [],
            };
        },
        async mounted()
        {
            // Set the direction for the popup button.
            if (this.$refs['icph-calendar'])
            {
                this.$refs['icph-calendar'].querySelectorAll('.icph-calendar-day').forEach((el) =>
                {
                    const bounding_rect = el.getBoundingClientRect();

                    if (bounding_rect.left > (window.innerWidth / 2))
                    {
                        el.querySelector('.icph-calendar-add-to').classList.add('icph-right');
                    }
                });
            }

            this.attributes = (await CalendarService.all()).data;
            this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        },
        methods:
        {
            format_month(date)
            {
                return new Date('2021-' + date.month + '-5').toLocaleString('default', { month: 'long' })
            },

            async add_event_to_calendar(attributes)
            {
                if (attributes.length)
                {
                    (await CalendarService.invite({ id: attributes[0].customData.id }))
                }
            }
        },
    }
</script>
