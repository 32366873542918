<template>
    <div
        class="icph-panel"
        :class="[
            'icph-align-' + align,
            {
                'icph-dark': dark
            }
        ]"
        >
        <div class="icph-panel-inner">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default
    {
        name: 'panel',
        props:
        {
            align:
            {
                type: String,
                default: 'left'
            },
            dark: Boolean,
        },
    }
</script>
