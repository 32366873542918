const Utils = {

    /**
     *  Are we at the bottom of the window?
     *
     *  @return bool
     */
    isAtBottomOfWindow: (el) =>
    {
        return Math.max(el.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + el.innerHeight === document.documentElement.scrollHeight;
    },

    /**
     *  Get element offset position
     *
     *  @param el   The DOM element.
     *
     *  @return object
     */
    offset: (el) =>
    {
        if (!el)
        {
            return {
                top: 0,
                left: 0,
            };
        }

        const box = el.getBoundingClientRect();

        return {
            top: box.top + window.pageYOffset - document.documentElement.clientTop,
            left: box.left + window.pageXOffset - document.documentElement.clientLeft
        };
    },

    /**
     *	Create and/or set a cookie.
     *
     *	@param name	The name of the cookie. This is like a key value.
     *	@param value The cookie value.
     *	@param expire_days How mutch time the cookie si valid. Value in days.
     */
    set_cookie: (name, value, expire_days) =>
    {
        var date = new Date();

        date.setDate(date.getDate() + expire_days);

        value = escape(value) + ((expire_days === null) ? '' : '; expires=' + date.toUTCString()) + ';path=/';

        document.cookie = name + '=' + value;
    },

    /**
     *	Get a cookie by its name.
     *
     *	@param name The name of the cookie.
     *
     *	@return If the function succeeds and the value has been found, the return will be the value of the cookie.
     *			If the cookie hasn't been found or the function failed, the return will be NULL.
     */
    get_cookie: (name) =>
    {
        var cookies = document.cookie.split(';');
        var cookie_name = '';
        var cookie_value = '';
        var cookie = [];

        for (var i = 0; i < cookies.length; i++) {
            cookie = cookies[i].split('=');

            cookie_name = cookie[0].replace(/^\s+|\s+$/g, '');
            cookie_value = cookie[1];

            if (cookie_name === name) {
                return unescape(cookie_value);
            }
        }

        return null;
    },
};

export { Utils };
