<template>
        <div class="icph-page-admin-overlay" :class="route_class">
            <div class="icph-page-admin">
                <header class="icph-admin-header">
                    <slot name="header">
                        <h1 class="admin-title">{{ $route.meta.title || '' }}</h1>
                        <h2 class="admin-subtitle">{{ $route.meta.subtitle || '' }}</h2>
                    </slot>
                </header>
                <router-link to="/" class="close-admin">X</router-link>
                <router-view v-slot="{ Component }">
                    <transition name="route-admin" mode="out-in">
                        <component :is="Component"></component>
                    </transition>
                </router-view>
            </div>
        </div>
</template>

<script>
    export default
    {
        name: 'Admin',
        props:
        {
            title: String,
            subtitle: String,
        },
        created()
        {
            if (this.user.role !== 'super-admin' && this.user.role !== 'admin')
            {
                this.$router.push('/');
            }
        },
        computed:
        {
            route_class()
            {
                return 'icph-admin-route-' + this.$route.name.replace(/(?!^)([A-Z])/g, '-$1').toLowerCase().replace(/\s/, '-');
            },
            user()
            {
                return this.$store.getters['Auth/user'];
            },
        },
    }
</script>
