<template>
  <div class="icph-page-briefings">
    <h1 class="icph-page-title">Briefings</h1>
    <panel class="icph-search-briefings" :dark="true">
      <base-input
        :round="true"
        v-model="search_term"
        icon-left="icon-search"
        label="Search briefings..."
        placeholder="Search"
        v-on:keyup.enter.stop="apply_filters"
      >
      </base-input>
    </panel>
    <div class="icph-view-toggles">
      <!-- <div>
                <label>Map view</label>
                <SwitchButton
                    v-model:checked="map_view"
                    on-text="ON"
                    off-text="OFF"
                    >
                </SwitchButton>
            </div> -->
      <div>
        <label>Timeline view</label>
        <SwitchButton
          v-model:checked="timeline_view"
          on-text="ON"
          off-text="OFF"
        >
        </SwitchButton>
      </div>
    </div>
    <panel class="icph-filters" :dark="true">
      <dropdown
        label="Topic"
        placeholder="Select topic"
        v-model="filters.topic"
        :options="topics"
        @update:modelValue="apply_filters"
      >
      </dropdown>
      <dropdown
        label="Document Type"
        placeholder="Select type"
        v-model="filters.type"
        :options="types"
        @update:modelValue="apply_filters"
      >
      </dropdown>
      <date-picker
        label="Date"
        placeholder="Select date"
        is-range
        v-model="filters.date"
        @update:modelValue="apply_filters"
      >
      </date-picker>
      <dropdown
        label="Geography"
        placeholder="Select country"
        :options="countries"
        v-model="filters.country"
        @update:modelValue="apply_filters"
      >
      </dropdown>
      <dropdown
        label="Event"
        placeholder="Select event"
        :options="events"
        v-model="filters.event"
        @update:modelValue="apply_filters"
      >
      </dropdown>
    </panel>
    <Button
      class="icph-search-button"
      icon="icon-search"
      color="primary"
      @click="apply_filters()"
    >
      Search
    </Button>
    <timeline
      v-if="timeline_view"
      v-model="filters.event"
      :loading="loading"
      :dataset="timeline_data"
      @update:modelValue="apply_filters"
    >
    </timeline>
    <articles :loading="loading" :hide-view-all="true">
      <post
        v-for="(brief, key) in briefings"
        :key="key"
        :image="brief.image_file"
        :title="brief.title"
        :date="brief.published_at"
        :excerpt="brief.summary"
        :link="'/briefing/' + brief.id"
      >
      </post>
      <Button
        class="icph-load-more"
        v-if="has_more_pages"
        color="primary"
        @click.prevent="load_more"
      >
        Load More Briefings
      </Button>
    </articles>
  </div>
</template>

<script>
import Panel from "@/components/Panels/Panel";
import BaseInput from "@/components/Inputs/BaseInput";
import Dropdown from "@/components/Inputs/Dropdown";
import DatePicker from "@/components/Inputs/DatePicker";
import Button from "@/components/Buttons/Button";
import SwitchButton from "@/components/Buttons/SwitchButton";
import Timeline from "@/components/Timeline";
import Articles from "@/components/Articles/Articles";
import Post from "@/components/Articles/Post";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";

import {
  BriefingService,
  EventService,
  CountryService,
  TopicService,
  RegionService,
} from "@/services";

// import { Utils } from '@/helpers';

export default {
  name: "Briefings",
  components: {
    Panel,
    BaseInput,
    Dropdown,
    DatePicker,
    Button,
    SwitchButton,
    Timeline,
    Articles,
    Post,
  },
  data() {
    return {
      loading: true,
      map_view: false,
      timeline_view: false,
      has_more_pages: false,
      per_page: 12,
      last_page: 1,
      page: 1,
      briefings: [],
      date: new Date(),
      topics: [],
      types: [
        'Issues and Background',
        'Key Processes and Opportunities',
        'Country Snapshots',
        'Country Strategies',
      ],
      countries: [],
      events: [],
      timeline_data: [],
      search_term: this.$store.state.BriefingSearch.searchTerm ?? "",
      filters: {
        topic: '',
        type: '',
        country: '',
        date: {},
        event: 0,
      },
    };
  },
  async mounted() {
    this.loading = true;

    await this.get_briefings();

    this.events = (
      await EventService.all({ all: 1, "per-page": -1 })
    ).data.data;

    this.countries = (await CountryService.all()).data;
    this.topics = (await TopicService.all()).data;
    this.regions = (await RegionService.all()).data;

    this.countries.forEach((item) => {
      item.value = item.id;
      item.label = item.name;
    });

    this.topics.forEach((item) => {
      item.value = item.name;
      item.label = item.name;
    });

    this.regions.forEach((item) => {
      item.value = item.code;
      item.label = "Region: " + item.name;
    });

    this.countries = [...this.regions, ...this.countries];

    this.events.forEach((item) => {
      item.value = item.id;
      item.label = item.title;
    });

    dayjs.extend(customParseFormat);
    dayjs.extend(isBetween);

    const data = [];

    let previous_quarter = 1;
    let previous_year = 0;

    let last_event;
    let no_current = true;
    const now = dayjs();

    this.events.forEach((event) => {
      const start_at = dayjs(event.start_at, "DD/MM/YYYY hh:mm A");
      const quarter = Math.floor((start_at.toDate().getMonth() + 3) / 3);
      const year = start_at.toDate().getFullYear();

      if (quarter !== previous_quarter || year !== previous_year) {
        data.push(`Q${quarter} ${year}`);
      }

      if (
        last_event &&
        last_event.start_at.diff(now) > 0 &&
        start_at.diff(now) < 0
      ) {
        last_event.current = true;
        no_current = false;
      }

      last_event = {
        start_at: start_at,
        date: start_at.format("D MMMM YYYY"),
        content: event.title,
        value: event.id,
        has_documents: event.briefings && event.briefings.length,
      };

      data.push(last_event);

      previous_quarter = quarter;
      previous_year = year;
    });

    this.timeline_data = data.reverse();

    if (no_current)
    {
      if (typeof data[data.length - 1] === 'string')
      {
        data[data.length - 2].current = true;
      }
      else
      {
        data[data.length - 1].current = true;
      }
    }

    // this.timeline_data = this.events.map((event) =>
    // {
    //     return {
    //         date: dayjs(event.start_at, 'DD/MM/YYYY hh:mm A').format('D MMMM YYYY'),
    //         content: event.title,
    //         value: event.id
    //     };
    // });

    // this.timeline_data = data.concat(this.timeline_data);

    this.timeline_view = this.timeline_data && this.timeline_data.length;

    this.loading = false;
  },
  methods: {
    async get_briefings() {
      const response = (
        await BriefingService.all({
          page: this.page,
          term: this.search_term,
          country: this.filters.country,
          topic: this.filters.topic,
          type: this.filters.type,
          publish_start: this.filters.date && this.filters.date.start,
          publish_end: this.filters.date && this.filters.date.end,
          event: this.filters.event,
          "per-page": this.per_page,
          "order-by": "favorite,published_at",
        })
      ).data;
      const briefings = response.data;

      if (this.page === 1) {
        this.briefings = briefings;
      } else {
        this.briefings.push(...briefings);
      }

      this.last_page = response.meta.last_page;
      this.has_more_pages = this.last_page > this.page;

      if (this.has_more_pages) {
        this.page++;
      }
    },

    async load_more() {
      this.loading = true;

      await this.get_briefings();

      this.loading = false;
    },

    async apply_filters() {
      this.loading = true;
      this.page = 1;
      this.$store.commit('BriefingSearch/setSearchTerm', this.search_term)

      await this.get_briefings();

      this.loading = false;
    },
  },
  beforeRouteLeave() {
    this.$store.commit("BriefingSearch/clearSearchTerm");
  },
  watch: {
    '$store.state.BriefingSearch.searchTerm': function(newVal) {
        this.search_term = newVal;

        if(!this.loading) {
            this.apply_filters();
        }
    }
  }
};
</script>
