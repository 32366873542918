<template>
    <div class="icph-inner-panel icph-has-icon">
        <slot name="title">
            <h2 class="icph-panel-title" v-if="title">
                <i v-if="icon" class="icph-panel-icon" :style="'background-image: url(\'' + icon + '\')'"></i>{{ title }}
            </h2>
        </slot>
        <div class="icph-inner-panel-inner">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default
    {
        name: 'inner-panel',
        props:
        {
            title: String,
            icon: String,
        },
    }
</script>
