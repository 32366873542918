<template>
    <div
        class="icph-page-recover"
        :class="{
            'icph-loading': loading
        }"
        >
        <panel>
            <h1 class="icph-lineunder">Reset Password</h1>
            <loader />
            <p class="icph-status-message">{{ response }}</p>
            <form @submit.prevent="recover">
                <base-input
                    v-if="!mail_sent"
                    type="email"
                    label="E-mail"
                    placeholder=""
                    v-model="model.email"
                    :error="get_errors('email')"
                    >
                </base-input>
                <Button type="submit">
                    {{ mail_sent ? 'Login' : 'Send Email' }}
                </Button>
                <p>If you're having any issues please contact <a href="mailto:info@icphub.org">info@icphub.org</a></p>
            </form>
        </panel>
    </div>
</template>

<script>
    import Panel from '@/components/Panels/Panel';
    import BaseInput from '@/components/Inputs/BaseInput';
    import Button from '@/components/Buttons/Button';
    import { AuthService } from '@/services';
    import Loader from '@/components/Loader';

    import useVuelidate from '@vuelidate/core';
    import { required, email } from '@vuelidate/validators';

    export default
    {
        name: 'Recover',
        components:
        {
            Panel,
            BaseInput,
            Button,
            Loader,
        },
        setup()
        {
            return { v$: useVuelidate() };
        },
        data()
        {
            return {
                submitted: false,
                loading: false,
                mail_sent: false,
                response: '',
                error: '',
                model:
                {
                    email: ''
                }
            };
        },
        validations()
        {
            return {
                model:
                {
                    email: { required, email },
                },
            };
        },
        methods:
        {
            async recover()
            {
                if (this.mail_sent)
                {
                    this.$router.push({ name: 'Login' });
                }

                const is_valid = await this.v$.$validate();

                if (!is_valid)
                {
                    return ;
                }

                this.loading = true;

                const response = await AuthService.request_reset_password(
                {
                    email: this.model.email,
                });

                this.mail_sent = response.data.success;
                this.response = response.data.status;

                this.loading = false;
            },

            get_errors(field, message)
            {
                const $err = this.v$.model[field];

                if ($err.$error)
                {
                    return message || $err.$errors.map((err) => err.$message).join(' - ');
                }

                return false;
            }
        },
    }
</script>
