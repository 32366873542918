<template>
    <teleport
        v-if="inserted"
        :to="teleportTarget"
        :disabled="disabled"
        >
        <transition
            appear
            name="icph-popup"
            >
            <div
                v-show="show"
                tabindex="-1"
                class="icph-popup-overlay"
                @click.self="close_popup"
                :class="[
                    'icph-color-' + color,
                    {
                        'icph-show': show,
                        'icph-has-title': title,
                        'icph-has-subtitle': subtitle,
                    }
                ]"
                v-bind="$attrs"
                >
                <div class="icph-popup">
                    <div class="icph-popup-header">
                        <slot name="header">
                            <h1 class="icph-popup-title" v-if="title">{{ title }}</h1>
                            <h2 class="icph-popup-subtitle" v-if="subtitle">{{ subtitle }}</h2>
                            <button class="icph-popup-close" @click="close_popup">X</button>
                        </slot>
                    </div>
                    <div class="icph-popup-inner">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </transition>
    </teleport>
</template>

<script>
    export default
    {
        inheritAttrs: false,
        name: 'popup',
        props:
        {
            show: Boolean,
            disabled: Boolean,
            color:
            {
                type: String,
                default: 'default',
            },
            title: String,
            subtitle: String,
            teleportTarget:
            {
                type: String,
                default: 'body',
                description: 'Where the popup HTML should be placed. Defaults to body'
            },
        },
        emits: ['update:show', 'close'],
        data()
        {
            return {
                inserted: true,
            };
        },
        methods:
        {
            close_popup()
            {
                this.$emit('update:show', false);
                this.$emit('close');
            },
        }
    }
</script>
