import { ApiService } from './api-service';

const AirtableCountryService =
{
    all()
    {
        return ApiService.get(`/api/airtable/countries`);
    },
};

const AirtableRegionService =
{
    all()
    {
        return ApiService.get(`/api/airtable/regions`);
    },
};

const AirtableThematicFocusService =
{
    all()
    {
        return ApiService.get(`/api/airtable/thematic-focuses`)
    }
}

const AirtableLevelOfAccessService =
{
    all()
    {
        return ApiService.get(`/api/airtable/level-of-access`)
    }
}

export {
    AirtableCountryService,
    AirtableRegionService,
    AirtableThematicFocusService,
    AirtableLevelOfAccessService
};
