<template>
    <div class="icph-admin-create" :class="{ 'icph-loading': loading, 'icph-fullscreen': fullscreen, }">
        <!-- <button class="icph-fullscreen-button" @click="this.fullscreen = !this.fullscreen;"></button> -->
        <loader />
        <perfect-scrollbar
            tag="form"
            @submit.prevent="save"
            :options="{ suppressScrollX: true }"
        >
            <header>
                <base-input
                    :round="true"
                    :error="get_errors('title')"
                    v-model="model.title"
                    label="Title"
                    placeholder="Enter Event Title"
                    >
                </base-input>
                <date-picker
                    label="Event Date"
                    placeholder="Select date"
                    mode="date"
                    :round="true"
                    v-model="model.start_at"
                    >
                </date-picker>
                <dropdown
                    label="Topic"
                    placeholder="Select your topics"
                    v-model="model.topics"
                    mode="tags"
                    :closeOnSelect="false"
                    :createTag="true"
                    :round="true"
                    :options="topics"
                    >
                </dropdown>
                <dropdown
                    label="Geography"
                    placeholder="Select countries"
                    v-model="model.countries"
                    mode="tags"
                    :round="true"
                    :options="countries"
                    >
                </dropdown>
            </header>
            <div class="icph-table">
                <div class="icph-table-body">
                    <p class="icph-table-empty" v-if="!model.briefings.length">There are no documents attached to this event.</p>
                    <perfect-scrollbar :options="{ suppressScrollX: true }">
                        <div class="icph-table-row" v-for="(brief, key) in model.briefings" :key="key">
                            <div class="icph-table-col">{{ key + 1 }}</div>
                            <div class="icph-table-col">{{ brief.title }}</div>
                            <div class="icph-table-col">{{ brief.type }}</div>
                            <div class="icph-table-col">{{ brief.published_at }}</div>
                            <div class="icph-table-col icph-no-bg">
                                <button class="icph-button icph-color-primary icph-button-remove" @click.prevent="remove_brief(brief)"></button>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
            </div>
            <footer class="icph-admin-footer">
                <div class="icph-attach-document-panel" :class="{ 'icph-expanded': show_document_panel }">
                    <header class="icph-attach-header">
                        <base-input
                            :round="true"
                            v-model="filter_briefings"
                            label="Filter"
                            placeholder="Search for document..."
                            >
                        </base-input>
                    </header>
                    <perfect-scrollbar tag="ul" :options="{ suppressScrollX: true }">
                        <li v-for="(brief, key) in filtered_briefings" :key="key" :class="{ 'icph-selected': brief._selected }" @click="brief._selected = !brief._selected">
                            {{ brief.title }}
                        </li>
                    </perfect-scrollbar>
                    <footer class="icph-attach-footer">
                        <Button
                            :round="true"
                            color="primary"
                            @click="update_documents"
                            >
                            ADD
                        </Button>
                    </footer>
                </div>
                <Button
                    :round="true"
                    color="primary"
                    @click="show_document_panel = !show_document_panel"
                    >
                    ATTACH DOCUMENT TO EVENT
                </Button>
                <SwitchButton
                    v-model:checked="model.publish"
                    on-text="PUBLISHED"
                    off-text="DRAFT"
                    >
                </SwitchButton>
                <Button
                    class="icph-cancel"
                    :round="true"
                    @click.prevent="$router.push({ name: 'EventsList' })"
                    >
                    CANCEL
                </Button>
                <Button
                    class="icph-save"
                    :round="true"
                    @click.prevent="save"
                    >
                    SAVE
                </Button>
            </footer>
        </perfect-scrollbar>
    </div>
</template>

<script>
    import BaseInput from '@/components/Inputs/BaseInput';
    import Dropdown from '@/components/Inputs/Dropdown';
    import DatePicker from '@/components/Inputs/DatePicker';
    import Button from '@/components/Buttons/Button';
    import SwitchButton from '@/components/Buttons/SwitchButton';
    import Loader from '@/components/Loader';

    import useVuelidate from '@vuelidate/core';
    import { required, minLength } from '@vuelidate/validators';

    import dayjs from 'dayjs';
    import customParseFormat from 'dayjs/plugin/customParseFormat';

    import { EventService, BriefingService, TopicService, CountryService } from '@/services';

    export default
    {
        name: 'EventsCreate',
        components:
        {
            BaseInput,
            Dropdown,
            DatePicker,
            Button,
            SwitchButton,
            Loader,
        },
        setup()
        {
            return { v$: useVuelidate() };
        },
        data()
        {
            return {
                is_new: !this.$route.params.id,
                loading: false,
                fullscreen: false,
                filter_briefings: '',
                show_document_panel: false,
                briefings: [],
                topics: [],
                countries: [],
                model:
                {
                    title: '',
                    topics: [],
                    countries: [],
                    briefings: [],
                    start_at: '',
                    published_at: '',
                    publish: false,
                },
            };
        },
        validations()
        {
            return {
                model:
                {
                    title: { required, minLength: minLength(3) },
                    start_at: { required },
                },
            };
        },
        computed:
        {
            filtered_briefings()
            {
                return this.briefings.filter((brief) =>
                {
                    return brief.title.includes(this.filter_briefings);
                });
            },
        },
        async created()
        {
            this.loading = true;

            this.countries = (await CountryService.all()).data;
            this.topics = (await TopicService.all()).data;
            this.briefings = (await BriefingService.all({ 'per-page': -1 })).data.data;

            this.countries.forEach((item) =>
            {
                item.value = item.id;
                item.label = item.name;
            });

            this.topics.forEach((item) =>
            {
                item.value = item.name;
                item.label = item.name;
            });

            if (!this.is_new)
            {
                const data = (await EventService.get(this.$route.params.id)).data;

                this.model = { ...this.model, ...data };
                this.model.publish = !!this.model.published_at;

                if (this.model.countries)
                {
                    this.model.countries = this.model.countries.map((item) => item.id);
                }

                dayjs.extend(customParseFormat);
                this.model.start_at = dayjs(this.model.start_at, 'DD/MM/YYYY hh:mm A').toDate();

                if (this.model.briefings)
                {
                    this.briefings.forEach((brief) =>
                    {
                        brief._selected = !!~this.model.briefings.findIndex((item) => item.id === brief.id);
                    });
                }
            }

            this.loading = false;
        },
        methods:
        {
            async save()
            {
                const is_valid = await this.v$.$validate();

                if (!is_valid)
                {
                    return ;
                }

                if (this.is_new)
                {
                    EventService.create(this.model).then(() =>
                    {
                        this.$router.push({ name: 'EventsList' });
                        this.$toast.success('Event was successfully created!');
                    },
                    (error) =>
                    {
                        if (error.data.message)
                        {
                            this.$toast.error(error.data.message);
                        }
                        else
                        {
                            this.$toast.error('Something went wrong. Please try again!');
                        }
                    });
                }
                else
                {
                    EventService.update(this.$route.params.id, this.model).then(() =>
                    {
                        this.$toast.success('Event was successfully updated!');
                    },
                    (error) =>
                    {
                        if (error.data.message)
                        {
                            this.$toast.error(error.data.message);
                        }
                        else
                        {
                            this.$toast.error('Something went wrong. Please try again!');
                        }
                    });
                }
            },

            update_documents()
            {
                this.show_document_panel = false;

                this.model.briefings = this.briefings.filter((brief) => brief._selected);
            },

            remove_brief(brief)
            {
                if (confirm('Are you sure you want to remove document from event?'))
                {
                    brief._selected = false;

                    this.update_documents();
                }
            },

            get_errors(field, message)
            {
                const $err = this.v$.model[field];

                if ($err.$error)
                {
                    return message || $err.$errors.map((err) => err.$message).join(' - ');
                }

                return false;
            }
        }
    }
</script>
