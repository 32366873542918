<template>
    <p> ICPH Privacy Notice*<br><br>
        At the International Climate Politics Hub we're committed to protecting and respecting your privacy. This policy explains when and why we collect personal information, how we use it, the conditions under which we may disclose it to others and how we keep it secure. Any questions regarding this policy and our privacy practices should be sent by email to info@icphub.org.<br><br>
        1. How do we collect information about you?<br><br>
        We obtain basic information about you (e.g. your name, organisation and email) when you are nominated to join the International Climate Politics Hub (ICPH). This information is usually shared with us via email or via our Airtable database, and may be supplemented by information we can find from external sources (e.g. an internet search). Once you have joined the network, we may invite you to share more detailed personal information (see list below) via email, our website, an online survey or at an in-person meeting.<br><br>
        2. What type of information is collected?<br><br>
        The personal information we collect may include your:<br>
        - Name (first, last)<br>
        - Email, secondary email<br>
        - Organisation, secondary organisation<br>
        - Role / job title / position<br>
        - Constituency (e.g. NGO / think tank / funder)<br>
        - Thematic expertise (e.g. science / finance)<br>
        - Skills / resources (e.g. communications / advocacy)<br>
        - City & country of residence<br>
        - Geographic coverage (i.e. region / country where your work takes place)<br>
        - Networks (e.g. top 10 organisations you work with)<br>
        - Level of access (e.g. Ministerial / CEO; senior official / C-suite etc.)<br>
        - Level of engagement (i.e. click rate for ICPH briefings)<br><br>
        3. How is your information used?<br><br>
        We may use your information to:<br>
        - Send you ICPH briefings and/or other ICPH communications.<br>
        - Create a profile of your interests and areas of expertise, in order to share relevant information with you and invite you to strategic conversations on that topic.<br>
        - Map and analyse our network as a whole, to better understand our reach and capacity to influence particular decisions.<br><br>
        4. Who has access to your information?<br><br>
        Your data may be viewed by members of the ICPH Secretariat (~10 employees / contractors of the European Climate Foundation) and the ICPH Global Team (~140 individuals from core partner think tanks / NGOs), but only where there is a legitimate need in order to fulfil our organisational purpose. Before gaining access to your personal information, contractors and members of the ICPH Global Team will sign a non-disclosure agreement, agreeing not to share your information any further and to use it only for the purpose of advancing the ICPH's work on accelerating climate action.<br><br>
        We will not sell or rent your information to third parties. We will not share your information with third parties for marketing purposes.<br><br>
        We may pass your information to our third party service providers for the purpose of providing services to you on our behalf. For example, we work with Airtable and Convertkit for processing ICPH email briefings. However, when we use third party service providers, we disclose only the personal information that is necessary in order to deliver the service. Please be reassured that we will not release your information to third parties beyond the ICPH network for them to use for their own direct purposes, unless you have requested us to do so or we are required to do so by law (for example, by a court order).<br><br>
        5. Your choices<br><br>
        You have a choice about whether or not you wish to receive information from us — and the right to request your information be deleted from our systems. If you do not want us to hold your personal data or send you ICPH briefings, then please let us know by contacting us at info@icphub.org. If you make a request, ICPH has one month to respond to you.<br><br>
        6. How you can access and update your information<br><br>
        You have the right to ask for a copy of the information the International Climate Politics Hub holds about you. If you would like to access your information, please contact us directly at info@icphub.org.<br><br>
        The accuracy of your information is important to us. We are in the process of developing a new website and, once launched, you will be asked to check and update your information via that platform. In the meantime, please email us if your circumstances change.<br><br>
        7. Security precautions in place to protect the unauthorized loss, misuse or alteration of your information<br><br>
        ICPH has reasonable security policies and procedures in place to protect your personal information from unauthorized loss, misuse, alteration, or destruction. Access to your personal information is strictly limited to those who have a need to know. Those individuals who have access to the data are required to sign a non-disclosure agreement, agreeing to maintain the confidentiality of such information. In the very unlikely scenario of a security breach, there is a procedure for safeguarding your information and ensuring it isn't shared any further.<br><br>
        We also make reasonable efforts to retain personal information only for so long: i) as the information is necessary to provide you with our services, ii) as necessary to comply with legal, regulatory, internal business or policy requirements, or iii) until you ask for the information to be deleted.<br><br>
        Should you wish to report a complaint or if you feel that ICPH has not addressed your concern in a satisfactory manner, you may contact the Dutch Data Protection Authority at Autoriteit Persoonsgegevens, Bezuidenhoutseweg 30, 2594 AV Den Haag, The Netherlands.<br><br>
        8. Transferring your information outside of Europe<br><br>
        Given the international nature of our network, the information which you provide to us may be transferred to countries outside the European Union (EU). These countries may not have similar data protection laws to the EU. By joining the ICPH network, you are agreeing to this transfer, storing or processing. If we transfer your information outside of the EU, we will take steps to ensure that appropriate security measures are taken with the aim of ensuring that your privacy rights continue to be protected as outlined in this policy<br><br>
        9. Review of this policy<br><br>
        We keep this policy under regular review. This policy was last updated in April 2021.<br><br>
    </p>
</template>
<script>
    export default {
        name: 'PrivacyPolicy',
    }
</script>
