<template>
    <div class="icph-page-network-directory">
        <h1 class="icph-page-title">Network Directory</h1>
        <panel
            class="icph-search-directory"
            :dark="true"
            >
            <base-input
                :round="true"
                v-model="search_term"
                icon-left="icon-search"
                label="Search contacts..."
                placeholder="Search"
                v-on:keyup.stop.enter="apply_filters"
                >
            </base-input>
        </panel>
        <panel
            class="icph-filters"
            :dark="true"
            >
            <dropdown
                label="Topic"
                placeholder="Select your topics"
                v-model="model.topic"
                :round="true"
                :options="topics"
                @update:modelValue="apply_filters"
                >
            </dropdown>
            <dropdown
                label="Location"
                placeholder="Select countries"
                v-model="model.countries"
                mode="multiple"
                @update:modelValue="apply_filters"
                :round="true"
                :options="countries"
                >
            </dropdown>
            <dropdown
                label="Role"
                placeholder="Select role"
                v-model="model.icp_role"
                @update:modelValue="apply_filters"
                :round="true"
                :options="icp_roles"
                >
            </dropdown>
        </panel>
        <Button class="icph-search-button" icon="icon-search" color="primary">
            Search
        </Button>
        <team :loading="loading">
            <team-member
                v-for="(member, key) in members"
                :member="member.id"
                :image="get_member_picture(member)"
                :name="member.name"
                :work-title="''"
                :lead="member.lead"
                :company="member.organization"
                :tags="member.thematic_scope"
                :key="key"
                >
            </team-member>
            <Button class="icph-load-more" v-if="has_more_pages" color="primary" @click.prevent="load_more">
                Load More
            </Button>
        </team>
    </div>
</template>

<script>
    import Panel from '@/components/Panels/Panel';
    import BaseInput from '@/components/Inputs/BaseInput';
    import Dropdown from '@/components/Inputs/Dropdown';
    import Button from '@/components/Buttons/Button';
    import Team from '@/components/Team/Team';
    import TeamMember from '@/components/Team/TeamMember';

    import { UserService, TopicService, CountryService } from '@/services';

    export default
    {
        name: 'NetworkDirectory',
        components:
        {
            Panel,
            BaseInput,
            Dropdown,
            Button,
            Team,
            TeamMember,
        },
        data()
        {
            return {
                loading: true,
                map_view: false,
                timeline_view: true,
                has_more_pages: false,
                per_page: 15,
                last_page: 1,
                page: 1,
                topics: [],
                countries: [],
                members: [],
                icp_roles: [
                    {
                        label: 'Secretariat',
                        value: 'secretariat',
                    },
                    {
                        label: 'Lead',
                        value: 'WG Lead',
                    }
                ],
                search_term: '',
                model:
                {
                    topic: '',
                    countries: [],
                },
            };
        },
        async mounted()
        {
            this.loading = true;

            this.countries = (await CountryService.all()).data;
            this.topics = (await TopicService.all()).data;

            this.countries.forEach((item) =>
            {
                item.value = item.id;
                item.label = item.name;
            });

            this.topics.forEach((item) => {
                item.value = item.name;
                item.label = item.name;
            })

            await this.get_members();

            setTimeout(() =>
            {
                this.loading = false;
            }, 100);
        },
        methods:
        {
            async get_members()
            {
                const response = (await UserService.all({
                    page: this.page,
                    term: this.search_term,
                    countries: this.model.countries,
                    topic: this.model.topic,
                    icp_role: this.model.icp_role,
                    'per-page': 15,
                    'with-role': 'admin,global-team'
                })).data;
                const members = response.data;

                if (this.page === 1)
                {
                    this.members = members;
                }
                else
                {
                    this.members.push(...members);
                }

                this.last_page = response.meta.last_page;
                this.has_more_pages = this.last_page > this.page

                if (this.has_more_pages)
                {
                    this.page++;
                }
            },

            async load_more()
            {
                this.loading = true;

                await this.get_members();

                this.loading = false;
            },

            async apply_filters()
            {
                this.loading = true;
                this.page = 1;

                await this.get_members();

                this.loading = false;
            },

            get_member_picture(member)
            {
                return (member.profile_picture_id ? process.env.VUE_APP_BACKEND_URL + 'api/files/' + member.profile_picture_id : 0)
            },
        }
    }
</script>
