<template>
    <svg class="icph-loader" viewBox="0 0 120 120">
        <circle class="icph-outer-circle" cx="60" cy="60" r="50"></circle>
        <circle class="icph-inner-circle" cx="60" cy="60" r="30"></circle>
    </svg>
</template>

<script>
    export default
    {
        name: 'loader',
    }
</script>
