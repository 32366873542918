<template>
    <div class="main-layout" :class="route_class">
        <Header :only-logo="$route.meta.only_when_logged_out || $route.meta.no_header" :sticky="header_sticky"></Header>
        <div class="icph-layout-content">
            <router-view v-slot="{ Component }">
                <transition name="route" mode="out-in">
                    <component :is="Component"></component>
                </transition>
            </router-view>
        </div>
        <Footer v-if="!$route.meta.only_when_logged_out && !$route.meta.no_header"></Footer>
        <AdminMenu v-if="!$route.meta.only_when_logged_out && !$route.meta.no_header && (user.role === 'super-admin' || user.role === 'admin')" />
        <button class="icph-scroll-to-top" @click="scroll_to_top" :class="{ 'icph-visible': show_scroll_to_top }"></button>
    </div>
</template>

<script>
    import Header from './Header';
    import Footer from './Footer';
    import AdminMenu from './AdminMenu';

    export default
    {
        name: 'MainLayout',
        components:
        {
            Header,
            Footer,
            AdminMenu,
        },
        data()
        {
            return {
                header_sticky: false,
                show_scroll_to_top: false,
            };
        },
        mounted()
        {
            document.querySelector('#app > .ps').onscroll = ((ev) =>
            {
                this.show_scroll_to_top = (ev.target.scrollTop > 300);
                this.header_sticky = (ev.target.scrollTop > 50);
            });


            document.body.classList.toggle('is-logged-in', this.user);
            document.body.classList.toggle('is-super-admin', this.user && this.user.role === 'super-admin');
        },
        computed:
        {
            route_class()
            {
                return 'icph-route-' + this.$route.name.toLowerCase().replace(/\s/, '-');
            },
            user()
            {
                return this.$store.getters['Auth/user'];
            },
        },
        methods:
        {
            scroll_to_top()
            {
                document.querySelector('#app > .ps').scrollTo(0, 0, 'smooth');
            },
        },
    }
</script>
