<template>
    <div class="icph-map" :class="{ 'icph-loading': loading, 'icph-expanded': expanded || fullyExpanded, 'icph-fully-expanded': fullyExpanded }">
        <loader />
        <div
            class="icph-map-viewport"
            ref="viewport"
            @mousedown="on_mousedown"
            @mousemove="on_mousemove"
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                :viewBox="map.viewBox"
                :aria-label="map.label"
                class="icph-svg-map"
                ref="map"
                >
                <slot name="before" />
                <path
                    v-for="(location) in map.locations"
                    :id="location.id"
                    :key="location.id"
                    :name="location.name"
                    :d="location.path"
                    class="svg-map__location"
                    :class="{ 'icph-selected': is_selected(location) }"
                    :aria-label="location.name"
                    @click="select($event, location)"
                />
                <!-- <g class="icph-label" ref="label" transform="translate(100 100) scale(.2)">
                    <rect width="454" height="119" rx="7" fill="#fff" />
                    <text fill="#2e656b" font-size="46" font-family="Montserrat-ExtraBold, Montserrat" font-weight="800" letter-spacing="0.1em"><tspan x="150" y="75">Portugal</tspan></text>
                    <ellipse cx="60" cy="60" rx="60" ry="60" transform="translate(-54 -54)" fill="#2e656b"/>
                    <text transform="translate(10 20)" fill="#fff" font-size="48" font-family="Montserrat-Black, Montserrat" font-weight="800" letter-spacing="0.1em"><tspan x="-27.024" y="0">12</tspan></text>
                    <path d="M20.561,0A20.7,20.7,0,0,1,41.322,20.561c0,10.78-12.975,28.745-20.761,35.333C12.975,49.107,0,31.54,0,20.561A20.532,20.532,0,0,1,20.561,0Zm0,12.177a8.485,8.485,0,1,1-8.384,8.384A8.479,8.479,0,0,1,20.561,12.177Z" fill="#2e656b" fill-rule="evenodd" transform="translate(75 30)" />
                </g> -->
                <slot name="after" />
            </svg>
        </div>
        <dropdown
            placeholder="Select country"
            mode="single"
            :options="locations"
            v-model="filters.country"
            @update:modelValue="select_country"
            >
        </dropdown>
        <div class="icph-toolbar">
            <nav class="icph-nav">
                <button class="icph-nav-top" @click="move_up"></button>
                <button class="icph-nav-right" @click="move_right"></button>
                <button class="icph-nav-bottom" @click="move_down"></button>
                <button class="icph-nav-left" @click="move_left"></button>
            </nav>
            <p class="icph-map-info">Select a country to retrieve information</p>
            <div class="icph-zoom">
                <button class="icph-zoom-in" @click="zoom_in">+</button>
                <button class="icph-zoom-out" @click="zoom_out">-</button>
            </div>
            <button class="icph-expand" v-if="!fullyExpanded" @click="expanded = !expanded">{{ expanded ? 'SHRINK' : 'EXPAND' }}</button>
        </div>
        <ul class="icph-map-legend" data-label="Legend | Ambition:">
            <li><b>Critically Insufficient</b></li>
            <li><b>Highly Insufficient</b></li>
            <li><b>Insufficient</b></li>
            <li><b>Partially Sufficient</b></li>
            <li><b>Sufficient</b></li>
        </ul>
        <div
            :class="{
                'icph-hidden': !overlay_visible
            }"
            class="icph-map-overlay"
             @click="overlay_visible = false"
            >
            <p>The Climate Diplomacy Snapshots provide a clear overview of what 24 countries + the EU should do, on climate and recovery, to keep the global average temperature increase to 1.5°C. Each has been prepared with the help of national experts, and will be regularly updated. The snapshots aim to support climate advocacy in the lead up to COP27.</p>
            <button class="icph-map-view" @click="overlay_visible = false">View Interactive Map</button>
        </div>
    </div>
    <div class="icph-map-additional-info">
        <p>The data is clear: accelerated and enhanced action is needed now to build resilience and avoid the worst impacts of climate change. As they seek to address the ongoing health, economic and social impacts of COVID-19, effects of the Russian invasion of Ukraine on global energy, supply chain and food security, and a closing window to fast track climate action to maintain a 1.5C, governments should seize opportunities to invest in a recovery that will build social, economic and climate resilience in the long-term.</p>
        <p>The Climate Diplomacy Snapshots aim to provide the climate community with a clear overview of what each country should do, on climate and recovery, to pursue these joint objectives and keep the global average temperature increase to 1.5°C. Each has been prepared with the help of national experts, and will be regularly updated. The snapshots aim to support climate advocacy in the lead up to COP27.</p>
    </div>
    <popup class="icph-popup-map" color="default" v-model:show="expand_popup" teleport-target="body">
        <div class="icph-inforgram-content"></div>
        <!-- <perfect-scrollbar :options="{ suppressScrollX: true }">
        </perfect-scrollbar> -->
    </popup>
</template>

<script>
    import Loader from  '@/components/Loader';
    import Popup from  '@/components/Popup';
    import Dropdown from '@/components/Inputs/Dropdown';
    import World from "@svg-maps/world";
    import { gsap } from 'gsap';

    const MIN_ZOOM_LEVEL = 2;
    const MAX_ZOOM_LEVEL = 16;

    export default
    {
        name: 'icph-map',
        components:
        {
            Loader,
            Popup,
            Dropdown,
        },
        props:
        {
            loading: Boolean,
            fullyExpanded: Boolean,
            modelValue: null,
            dataset:
            {
                type: Array,
                required: false,
            },
            countries: null,
            infograms: null,
        },
        emits: ['update:modelValue'],
        data()
        {
            return {
                map: World,
                zoom_level: 7,
                start_x: 0,
                start_y: 0,
                x_offset: 0,
                y_offset: 0,
                map_width: 1010,
                map_height: 666,
                current_map_width: 1010,
                current_map_height: 666,
                filters: {
                    country: '',
                },
                locations: null,
                mousedown: false,
                expanded: false,
                expand_popup: false,
                overlay_visible: true,
            };
        },
        computed:
        {
            value:
            {
                get()
                {
                    return this.modelValue;
                },
                set(value)
                {
                    this.$emit('update:modelValue', value);
                },
            },

            is_empty()
            {
                return !this.loading && !this.dataset.length;
            },
        },
        created()
        {
            const parts = this.map.viewBox.split(' ');

            this.map_width = parts[2];
            this.map_height = parts[3];

            document.addEventListener('mouseup', this.on_mouseup);

            this.locations = this.map.locations.filter((item) => item.id.toUpperCase() in this.infograms).map((item) =>
            {
                return {
                    value: item.id.toUpperCase(),
                    label: item.name,
                };
            });

            // const viewport = this.$refs.viewport;
        },
        mounted()
        {
            this.update(false);
        },
        unmounted()
        {
            document.removeEventListener('mouseup', this.on_mouseup);
        },
        methods:
        {
            move_to(x, y, animate)
            {
                // this.x_offset = Math.min(1000, Math.max(x, 0));
                // this.y_offset = Math.min(1000, Math.max(y, 0));

                this.x_offset = Math.max(-100, Math.min(this.current_map_width - (this.$refs.viewport.offsetWidth / 2), x));
                this.y_offset = Math.max(-100, Math.min(this.current_map_height - (this.$refs.viewport.offsetHeight / 2), y));

                // this.x_offset = x;
                // this.y_offset = y;

                // console.log('x: ', x, 'y:', y);
                // console.log('x_offset: ', this.x_offset, 'y_offset:', this.y_offset);

                this.update(animate);
            },

            move_up()
            {
                this.move_to(this.x_offset, this.y_offset - 100, true);
            },

            move_down()
            {
                this.move_to(this.x_offset, this.y_offset + 100, true);
            },

            move_left()
            {
                this.move_to(this.x_offset - 100, this.y_offset, true);
            },

            move_right()
            {
                this.move_to(this.x_offset + 100, this.y_offset, true);
            },

            zoom_to(level)
            {
                level = Math.min(MAX_ZOOM_LEVEL, Math.max(MIN_ZOOM_LEVEL, level));

                this.zoom_level = level;

                this.update();
            },

            zoom_in()
            {
                this.zoom_to(this.zoom_level + 1);
            },

            zoom_out()
            {
                this.zoom_to(this.zoom_level - 1);
            },

            view_box()
            {
                const zoom_level = (MAX_ZOOM_LEVEL / 2) / this.zoom_level;
                // const zoom_level = 1;
                const x = this.x_offset;
                const y = this.y_offset;
                const map_width = this.map_width * zoom_level;
                const map_height = this.map_height * zoom_level;

                this.current_map_width = map_width;
                this.current_map_height = map_height;

                return `${x} ${y} ${map_width} ${map_height}`;
            },

            update(animate)
            {
                if (animate)
                {
                    gsap.to(this.$refs.map, {
                        duration: 1,
                        attr: { viewBox: this.view_box() },
                        ease: 'power3.inOut',
                    });
                }
                else
                {
                    this.$refs.map.setAttribute('viewBox', this.view_box());
                }
            },

            select(ev, item)
            {
                const code = item.id.toUpperCase();
                // const rect = ev.target.getBoundingClientRect();

                this.value = this.countries.filter((country) =>
                {
                    return country.code === code;
                })[0];

                let scr = '';

                // console.log('code - ', code);

                if (this.infograms && this.infograms[code])
                {
                    const path = this.infograms[code];

                    if (path.indexOf('http') === 0)
                    {
                        scr = document.createElement('iframe');
                        scr.frameborder = 0;
                        // scr.allowfullscreen = '';
                        scr.className = 'icph-our-frame';
                        scr.src = path;
                    }
                    else
                    {
                        scr = document.createElement('script');
                        scr.id = 'infogram_0_' + this.infograms[code];
                        scr.src = 'https://e.infogram.com/js/dist/embed.js?D8H';
                    }
                }
                else
                {
                    return ;
                }

                document.querySelector('.icph-inforgram-content').innerHTML = '';
                document.querySelector('.icph-inforgram-content').append(scr);

                this.expand_popup = true;

                // this.$refs.label.setAttribute('transform', `translate(${rect.x} ${rect.y}) scale(.2)`);
            },

            select_country()
            {
                const country = (this.filters.country ? this.filters.country : null);

                if (country)
                {
                    this.select(null, {id: country});
                }
            },

            is_selected(item)
            {
                return this.value && this.value.code === item.id.toUpperCase();
            },

            on_mousedown(ev)
            {
                this.mousedown = true;
                this.start_x = ev.offsetX + this.x_offset;
                this.start_y = ev.offsetY + this.y_offset;
            },

            on_mousemove(ev)
            {
                if (this.mousedown)
                {
                    const x = this.start_x - ev.offsetX;
                    const y = this.start_y - ev.offsetY;

                    this.move_to(x, y);
                }
            },

            on_mouseup()
            {
                this.mousedown = false;
            },
        },
    }
</script>
