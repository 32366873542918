import { ApiService } from './api-service';

const SlackService =
{
    all(channel_id)
    {
        return ApiService.get(`/api/slack/read/${channel_id}`);
    },
};

export {SlackService};
