<template>
    <div
        class="icph-page-login icph-page-activate"
        :class="{
            'icph-loading': loading
        }"
        >
        <panel>
            <h1 class="icph-lineunder">Activate account</h1>
            <p>Please enter your email address here and you will shortly receive a link via email to activate your new ICP Hub website account (please check your spam)</p>
            <loader />
            <form @submit.prevent="activate">
                <p class="icph-status-message" v-if="success == false && !status.message">Your account has already been activated! Please go to the <router-link :to="{ name: 'Login' }">sign in</router-link> page and input your log in details. Contact info@icphub.org for assistance</p>
                <p class="icph-status-message" v-if="success == false && status.message">{{ status.message }}</p>
                <p class="icph-status-message" v-if="success == true">An activation link was sent to your email!</p>
                <base-input
                    label="E-mail"
                    type="email"
                    v-model="model.email"
                    :error="get_errors('email')"
                    >
                </base-input>
                <Button type="submit">Activate</Button>
                <p>If you're having any issues please contact <a href="mailto:info@icphub.org">info@icphub.org</a></p>
            </form>
        </panel>
    </div>
</template>

<script>
    import Panel from '@/components/Panels/Panel';
    import BaseInput from '@/components/Inputs/BaseInput';
    import Button from '@/components/Buttons/Button';
    import Loader from '@/components/Loader';

    import { AuthService } from '@/services';

    import useVuelidate from '@vuelidate/core';
    import { required, email } from '@vuelidate/validators';

    export default
    {
        name: 'ActiveAccount',
        components:
        {
            Panel,
            BaseInput,
            Button,
            Loader,
        },
        setup()
        {
            return { v$: useVuelidate() };
        },
        data()
        {
            return {
                submitted: false,
                status: {},
                success: null,
                loading: false,
                error: '',
                model:
                {
                    email: this.$route.params.email,
                },
            };
        },
        validations()
        {
            return {
                model:
                {
                    email: { required, email },
                },
            };
        },
        methods:
        {
            async activate()
            {
                const is_valid = await this.v$.$validate();

                if (!is_valid)
                {
                    return ;
                }

                this.loading = true;

                this.status = (await AuthService.request_activate(this.model)).data;

                this.success = this.status.success;

                this.loading = false;

                // if (this.success)
                // {
                //     this.$router.push({ name: 'Login' });
                // }
            },

            get_errors(field, message)
            {
                const $err = this.v$.model[field];

                if ($err.$error)
                {
                    return message || $err.$errors.map((err) => err.$message).join(' - ');
                }

                return false;
            }
        },
    }
</script>
