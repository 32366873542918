<template>
    <div class="icph-admin-create" :class="{ 'icph-loading': loading, 'icph-fullscreen': fullscreen, }">
        <!-- <button class="icph-fullscreen-button" @click="this.fullscreen = !this.fullscreen;"></button> -->
        <loader />
        <perfect-scrollbar
            tag="form"
            @submit.prevent="save"
            :options="{ suppressScrollX: true }"
        >
            <header>
                <base-input
                    :round="true"
                    :error="get_errors('name')"
                    v-model="model.name"
                    label="Name"
                    placeholder="Enter Topic Name"
                    >
                </base-input>
            </header>
            <footer class="icph-admin-footer">
                <Button
                    class="icph-cancel"
                    :round="true"
                    @click.prevent="$router.push({ name: 'TopicsList' })"
                    >
                    CANCEL
                </Button>
                <Button
                    class="icph-save"
                    :round="true"
                    @click.prevent="save"
                    >
                    SAVE
                </Button>
            </footer>
        </perfect-scrollbar>
    </div>
</template>

<script>
    import BaseInput from '@/components/Inputs/BaseInput';
    import Button from '@/components/Buttons/Button';
    import Loader from '@/components/Loader';

    import useVuelidate from '@vuelidate/core';
    import { required, minLength } from '@vuelidate/validators';

    import { TopicService } from '@/services';

    export default
    {
        name: 'TopicsCreate',
        components:
        {
            BaseInput,
            Button,
            Loader,
        },
        setup()
        {
            return { v$: useVuelidate() };
        },
        data()
        {
            return {
                is_new: !this.$route.params.id,
                loading: false,
                fullscreen: false,
                model:
                {
                    name: '',
                },
            };
        },
        validations()
        {
            return {
                model:
                {
                    name: { required, minLength: minLength(3) },
                },
            };
        },
        computed:
        {
        },
        async created()
        {
            this.loading = true;

            if (!this.is_new)
            {
                const data = (await TopicService.get(this.$route.params.id)).data;

                this.model = { ...this.model, ...data };
            }

            this.loading = false;
        },
        methods:
        {
            async save()
            {
                const is_valid = await this.v$.$validate();

                if (!is_valid)
                {
                    return ;
                }

                if (this.is_new)
                {
                    TopicService.create(this.model).then(() =>
                    {
                        this.$router.push({ name: 'TopicsList' });
                        this.$toast.success('Topic was successfully created!');
                    },
                    (error) =>
                    {
                        if (error.data.message)
                        {
                            this.$toast.error(error.data.message);
                        }
                        else
                        {
                            this.$toast.error('Something went wrong. Please try again!');
                        }
                    });
                }
                else
                {
                    TopicService.update(this.$route.params.id, this.model).then(() =>
                    {
                        this.$toast.success('Topic was successfully updated!');
                    },
                    (error) =>
                    {
                        if (error.data.message)
                        {
                            this.$toast.error(error.data.message);
                        }
                        else
                        {
                            this.$toast.error('Something went wrong. Please try again!');
                        }
                    });
                }
            },

            get_errors(field, message)
            {
                const $err = this.v$.model[field];

                if ($err.$error)
                {
                    return message || $err.$errors.map((err) => err.$message).join(' - ');
                }

                return false;
            }
        }
    }
</script>
