<template>
    <label :for="unique_id"
        type="button"
        class="icph-button icph-upload-button"
        :class="{
            [`icph-color-${color}`]: color,
            'icph-round': round,
            'icph-has-icon': icon || iconRight,
            'icph-disabled': disabled,
            'icph-label-button': isLink,
            'icph-has-file': has_file,
        }"
        >
        <slot name="loading">
            <i v-if="loading" class="icph-loading"></i>
        </slot>
        <slot name="icon">
            <i v-if="icon" class="icph-icon" :class="icon"></i>
        </slot>
        <span v-if="has_file">{{ changeCaption || 'CHANGE FILE' }}</span>
        <span v-else><slot></slot></span>
        <slot name="icon-right">
            <i v-if="iconRight" class="icph-icon" :class="iconRight"></i>
        </slot>

        <span v-if="has_file" class="icph-remove-file icon-action-remove" @click.prevent="remove_file"></span>

        <div v-if="has_file" class="icph-upload-preview">
            <img v-if="accept === 'images'" :src="preview_content" />
            <span v-else>{{ preview_content }}</span>
        </div>

        <input
            :id="unique_id"
            ref="file"
            type="file"
            :accept="accept_types"
            @input="handle_file_upload"
            />
    </label>
</template>

<script>
    export default
    {
        name: 'upload-button',
        props:
        {
            round: Boolean,
            color: String,
            icon: String,
            iconRight: String,
            loading: Boolean,
            disabled: Boolean,
            changeCaption: String,
            modelValue: [String, Number, Object],
            accept: String,
            isLink:
            {
                type: Boolean,
                description: 'Whether button is a link (no background and borders)'
            },
        },
        emits: ['update:modelValue', 'file'],
        computed:
        {
            file:
            {
                get()
                {
                    if (this.accept === 'images')
                    {
                        this.preview_content = process.env.VUE_APP_BACKEND_URL + 'api/files/2'; // eslint-disable-line
                    }
                    else
                    {
                        this.preview_content = this.modelValue.filename; // eslint-disable-line
                    }

                    return this.modelValue;
                },
                set(value)
                {
                    this.$emit('update:modelValue', value);
                },
            },

            has_file()
            {
                return this.file.name !== undefined || this.file.filename !== undefined;
            },
        },
        data()
        {
            return {
                accept_types: '',
                preview_content: '',
            };
        },
        created()
        {
            this.unique_id = Math
                .random()
                .toString(16)
                .slice(2);

            if (this.accept)
            {
                if (this.accept === 'images')
                {
                    this.accept_types = '.jpg,.png,.webp, images/*';
                }
                else if (this.accept === 'pdf')
                {
                    this.accept_types = '.pdf, application/pdf';
                }
                else
                {
                    this.accept_types = this.accept;
                }
            }
        },
        methods:
        {
            handle_file_upload()
            {
                if (this.$refs.file.files.length > 0)
                {
                    this.file = this.$refs.file.files[0];

                    if (this.file_id)
                    {
                        this.file.id = this.file_id;
                    }

                    this.$emit('file', this.file);

                    if (this.accept === 'images')
                    {
                        this.preview_content = URL.createObjectURL(this.$refs.file.files[0]);
                    }
                    else
                    {
                        this.preview_content = this.$refs.file.files[0].name;
                    }
                }
            },

            remove_file()
            {
                if (confirm(`Are you sure you want to remove '${this.file.name}'?`))
                {
                    this.file = {}
                }
            },
        },
    }
</script>
