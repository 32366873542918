import { createStore } from 'vuex';

import { Auth, BriefingSearch } from './modules';

export default createStore({
    modules:
    {
        Auth,
        BriefingSearch
    },
    state: {}
});
