<template>
    <div
        class="icph-team-member"
        :class="{
            'icph-contact-member': show_contact_member,
            'icph-team-member-selected': show_contact_member,
            'icph-no-image': !image,
            'icph-expanded': expanded,
        }"
        @click="this.expanded = !this.expanded"
        >
        <div class="icph-team-head">
            <div class="icph-team-info">
                <span class="icph-team-name" v-if="name">{{ name }}</span>
                <span class="icph-team-work-title" v-if="workTitle">{{ workTitle }}</span>
                <span class="icph-team-company" v-if="company">{{ company }}</span>
            </div>
            <img :src="image || require('@/assets/images/profile-no-plus.svg')" />
        </div>
        <div class="icph-lead" v-if="lead">
            <p>ICPH Lead</p>
            <ul class="icph-lead-list">
                <li v-for="(l, index) in lead" :key="'l_' + index">{{ l }}</li>
            </ul>
        </div>
        <button class="icph-team-send-message" @click="show_member_contact">Send Message</button>
        <form class="icph-team-member-contact" @submit.prevent="send" :class="{ 'icph-loading': sending }">
            <loader />
            <p v-if="success" class="icph-contact-success">Message successfully sent</p>
            <label>Message</label>
            <textarea v-model="model.message" placeholder="Enter your message"></textarea>
            <button type="submit" class="icph-send">Send</button>
            <button class="icph-cancel" @click.stop.prevent="abort_member_contact()">Cancel</button>
        </form>
        <div class="icph-team-side-panel">
            <ul class="icph-team-tags">
                <li class="icph-team-tag" v-for="(tag, index) in tags" :key="index">{{ tag }}</li>
            </ul>
            <button class="icph-team-expand">Expand</button>
        </div>
    </div>
</template>

<script>
    import Loader from  '@/components/Loader';

    import { ContactService } from '@/services';

    export default
    {
        name: 'team-member',
        components:
        {
            Loader,
        },
        props:
        {
            member: null,
            image: null,
            name: String,
            workTitle: String,
            company: String,
            tags: [Array, String],
            lead: [Array, String],
        },
        data()
        {
            return {
                show_contact_member: false,
                sending: false,
                success: false,
                expanded: false,
                model:
                {
                    member: 0,
                    message: '',
                }
            };
        },
        methods:
        {
            async send()
            {
                this.sending = true;
                this.model.member = this.member;
                this.success = (await ContactService.member(this.model)).data.success;
                this.sending = false;
            },

            show_member_contact()
            {
                this.show_contact_member = true;
            },

            abort_member_contact()
            {
                this.show_contact_member = false;
                this.expanded = false;
            },

        },
    }
</script>
