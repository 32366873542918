<template>
    <div
        :class="{
            'icph-round': round,
            'icph-focused': focused,
            'icph-error': error,
            'icph-success': !error && touched,
            'icph-expanded': expanded,
            'icph-dark': dark,
            'has-label': label,
            'has-icon': has_icon,
        }"
        class="icph-input icph-date-picker"
        v-click-outside="close_date_picker"
        >
        <slot name="label">
            <label v-if="label">{{ label }}</label>
        </slot>
        <div class="icph-input-inner">
            <slot name="iconLeft">
                <i class="icph-input-icon-left" :class="iconLeft" v-if="iconLeft"></i>
            </slot>
            <span
                class="icph-caption"
                @click="toggle_date_picker"
                :class="{
                    'icph-placeholder': !(this.value || this.selected)
                }">{{ caption }}</span>
            <i class="icph-date-picker-icon icon-chevron-down"></i>
            <i class="icph-date-picker-icon multiselect-clear-icon" v-if="isRange && this.value && this.value.start" @click="this.value = {}"></i>
            <div class="icph-date-picker-menu">
                <v-date-picker v-model="value" :mode="mode" :is-range="isRange" />
            </div>
        </div>
        <slot name="error" v-if="error || $slots.error">
            <p class="icph-error">{{ error }}</p>
        </slot>
    </div>
</template>

<script>
    export default
    {
        name: 'date-picker',
        inheritAttrs: false,
        props:
        {
            label: String,
            required: Boolean,
            round: Boolean,
            dark: Boolean,
            placeholder: String,
            mode: String,
            isRange: Boolean,
            modelValue: [String, Number, Object],
            error:
            {
                type: String,
                default: ''
            },
            iconLeft: String,
        },
        emits: ['update:modelValue', 'change'],
        data()
        {
            return {
                selected: false,
                focused: false,
                touched: false,
                expanded: false,
                date: new Date(),
            };
        },
        created()
        {
        },
        computed:
        {
            value:
            {
                get()
                {
                    return this.modelValue;
                },
                set(value)
                {
                    this.$emit('update:modelValue', value);
                },
            },

            caption()
            {
                if (this.isRange && this.value.start instanceof Date && !isNaN(this.value.start))
                {
                    const options = {
                        year: 'numeric', month: 'numeric', day: 'numeric',
                        hour12: true,
                    };

                    if (this.mode === 'dateTime')
                    {
                        options['hour'] = 'numeric';
                        options['minute'] = 'numeric';
                    }

                    return (new Intl.DateTimeFormat('en-GB', options).format(this.value.start).toUpperCase()) + ' - ' +
                    (new Intl.DateTimeFormat('en-GB', options).format(this.value.end).toUpperCase());
                }
                else if (this.value instanceof Date && !isNaN(this.value))
                {
                    const options = {
                        year: 'numeric', month: 'numeric', day: 'numeric',
                        hour12: true,
                    };

                    if (this.mode === 'dateTime')
                    {
                        options['hour'] = 'numeric';
                        options['minute'] = 'numeric';
                    }

                    return new Intl.DateTimeFormat('en-GB', options).format(this.value).toUpperCase();
                }

                return this.placeholder || '';
            },
            has_icon()
            {
                const { iconLeft } = this.$slots;

                return (
                    iconLeft !== undefined ||
                    this.iconLeft !== undefined
                );
            },
            listeners()
            {
                return {
                    input: this.on_input,
                    blur: this.on_blur,
                    focus: this.on_focus,
                };
            },
        },
        methods:
        {
            on_input(ev)
            {
                if (!this.touched)
                {
                    this.touched = true;
                }

                this.$emit('input', ev.target.value);
            },
            on_focus()
            {
                this.focused = true;
            },
            on_blur()
            {
                this.focused = false;
            },
            toggle_date_picker()
            {
                this.expanded = !this.expanded;
                this.$emit('change', this.selected);
            },
            close_date_picker()
            {
                this.expanded = false;
                this.$emit('change', this.selected);
            },
            select_option(option)
            {
                this.selected = option;
                this.value = option;
                this.close_date_picker();
            }
        }
    }
</script>
