<template>
    <div class="icph-team" :class="{ 'icph-loading': loading }">
        <loader />
        <div class="icph-team-inner">
            <slot></slot>
        </div>
        <!-- <nav class="icph-team-nav">
            <button class="icph-team-prev"></button>
            <button class="icph-team-next"></button>
        </nav> -->
    </div>
</template>

<script>
    import Loader from  '@/components/Loader';

    export default
    {
        name: 'team',
        components:
        {
            Loader,
        },
        props:
        {
            loading: null,
        },
    }
</script>
