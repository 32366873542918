import { ApiService } from './api-service';

const UploadService =
{
    tinymce(data)
    {
        return ApiService.post('/api/uploads/tinymce', data);
    },
};

export {UploadService};
