<template>
    <router-link
        class="icph-article"
        :class="{
            'icph-no-image': noImage,
            'icph-featured': featured,
            'icph-has-briefing': briefing
        }"
        :to="link"
    >
        <template v-if="!noImage">
            <lazy-img :src="get_image()" v-if="showImage" />
            <div v-else class="icph-article-image-placeholder icon-briefings"></div>
        </template>
        <div class="icph-article-info">
            <h2>{{ title }}</h2>
            <span class="icph-article-date">{{ date }}</span>
            <p>{{ excerpt }}</p>
        </div>
        <div class="icph-article-buttons">
            <!-- <button class="icph-read-more" @click="read_more">Read More</button> -->
            <Button class="icph-briefing" @click="navigate_to_briefing" v-if="briefing">BRIEFING</Button>
        </div>
    </router-link>
</template>

<script>
    import Button from '@/components/Buttons/Button';
    import LazyImg from '@/components/LazyImg';

    export default
    {
        name: 'post',
        components:
        {
            Button,
            LazyImg,
        },
        props:
        {
            featured: Boolean,
            noImage: Boolean,
            image: [String, Object],
            title: String,
            date: String,
            excerpt: String,
            briefing: String,
            link: String,
        },
        data()
        {
            return {
                showImage: true,
            };
        },
        watch: {
            image()
            {
                this.showImage = null;
                setTimeout(() => this.showImage = this.image);
            },
        },
        methods:
        {
            read_more()
            {
                this.$router.push(this.link);
            },
            navigate_to_briefing()
            {
                this.$router.push(this.briefing);
            },
            get_image()
            {
                if (this.image && this.image.id)
                {
                    return process.env.VUE_APP_BACKEND_URL + 'api/files/' + this.image.id;
                }
            }
        },
    }
</script>
