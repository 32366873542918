<template>
    <ul class="icph-tags" :data-label="label">
        <li v-for="(tag, index) in tags" :key="index">{{ tag }}</li>
    </ul>
</template>

<script>
    export default
    {
        name: 'tags',
        props:
        {
            tags:
            {
                type: [Array, String],
                required: true,
            },
            label: String,
        },
    }
</script>
