import { ApiService } from './api-service';

const ContactService =
{
    send(data)
    {
        return ApiService.post('/api/contact/send', data);
    },

    member(data)
    {
        return ApiService.post('/api/contact/member', data);
    },
};

export {ContactService};
