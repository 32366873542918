<template>
    <div
        class="icph-page-login icph-page-activate"
        :class="{
            'icph-loading': loading
        }"
        >
        <panel>
            <h1 class="icph-lineunder">Activate account</h1>
            <p>Welcome to the new International Climate Politics Hub Website. Please activate your user account by creating a new password.</p>
            <loader />
            <form @submit.prevent="activate">
                <base-input
                    label="E-mail"
                    type="email"
                    v-model="model.email"
                    :error="get_errors('email')"
                    :disabled="true"
                    >
                </base-input>
                <base-input
                    label="Password"
                    type="password"
                    v-model="model.password"
                    :error="get_errors('password')"
                    >
                </base-input>
                <base-input
                    label="Confirm Password"
                    type="password"
                    v-model="model.confirm"
                    :error="get_errors('confirm')"
                    >
                </base-input>
                <Button type="submit">Activate</Button>
                <p>If you're having any issues please contact <a href="mailto:info@icph.org">info@icph.org</a></p>
            </form>
        </panel>
    </div>
</template>

<script>
    import Panel from '@/components/Panels/Panel';
    import BaseInput from '@/components/Inputs/BaseInput';
    import Button from '@/components/Buttons/Button';
    import Loader from '@/components/Loader';

    import { AuthService } from '@/services';

    import useVuelidate from '@vuelidate/core';
    import { helpers, required, email, minLength } from '@vuelidate/validators';

    export default
    {
        name: 'CreatePassword',
        components:
        {
            Panel,
            BaseInput,
            Button,
            Loader,
        },
        setup()
        {
            return { v$: useVuelidate() };
        },
        data()
        {
            return {
                submitted: false,
                loading: false,
                error: '',
                model:
                {
                    email: this.$route.params.email,
                    token: this.$route.params.token,
                    password: '',
                    confirm: '',
                },
            };
        },
        validations()
        {
            return {
                model:
                {
                    email: { required, email },
                    password: { required, minLength: minLength(6) },
                    confirm: {
                        sameAsPass: helpers.withMessage('Confirm password should be the same as password', () => this.model.password === this.model.confirm)
                    },
                },
            };
        },
        methods:
        {
            async activate()
            {
                const is_valid = await this.v$.$validate();

                if (!is_valid)
                {
                    return ;
                }

                this.loading = true;

                await AuthService.activate(this.model);

                this.loading = false;

                this.$router.push({ name: 'Login' });
            },

            get_errors(field, message)
            {
                const $err = this.v$.model[field];

                if ($err.$error)
                {
                    return message || $err.$errors.map((err) => err.$message).join(' - ');
                }

                return false;
            }
        },
    }
</script>
