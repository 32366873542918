// import Vue from 'vue';
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store';

// Import one of the available themes
// import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';

import '@/assets/less/style.less';

import 'v-calendar/dist/style.css';

/**
 *  Plugins
 */
import GlobalDirectives from './plugins/global-directives';
import Vue3TouchEvents from 'vue3-touch-events';
import VCalendar from 'v-calendar';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import VueToast from 'vue-toast-notification';

import UserbackPlugin from '@userback/vue';

/**
 *  Services
 */
import { ApiService, TokenService } from '@/services';

ApiService.init(process.env.VUE_APP_BACKEND_URL);

if (TokenService.has_token())
{
    ApiService.set_header();
}

const icph_app = createApp(App);

icph_app
    .use(UserbackPlugin,
    {
        token: process.env.USERBACK_TOKEN,
    })
    .use(router)
    .use(store)
    .use(VueToast, {
        position: 'top',
        // duration: 1000000,
    })
    .use(Vue3TouchEvents)
    .use(VCalendar)
    .use(PerfectScrollbar)
    .use(GlobalDirectives)
    .mount('#app');
