import { Utils } from '@/helpers';

const TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';

const TokenService =
{
    get_token()
    {
        return Utils.get_cookie(TOKEN_KEY);

        // try
        // {
        //     return localStorage.getItem(TOKEN_KEY);
        // }
        // catch (SecurityError)
        // {
        //     return sessionStorage.getItem(TOKEN_KEY);
        // }
    },

    has_token()
    {
        return !!this.get_token();
    },

    save_token(token)
    {
        localStorage.setItem(TOKEN_KEY, token);
        sessionStorage.setItem(TOKEN_KEY, token);
        Utils.set_cookie(TOKEN_KEY, token, 10);
    },

    remove_token()
    {
        localStorage.removeItem(TOKEN_KEY);
        sessionStorage.removeItem(TOKEN_KEY);
        Utils.set_cookie(TOKEN_KEY, '', 10);
    },

    get_refresh_token()
    {
        return localStorage.getItem(REFRESH_TOKEN_KEY);
    },

    save_refresh_token(token)
    {
        localStorage.setItem(REFRESH_TOKEN_KEY, token);
    },

    remove_refresh_token()
    {
        localStorage.removeItem(REFRESH_TOKEN_KEY);
    },
};

export { TokenService };
