import { ApiService } from './api-service';

const BriefingService =
{
    all(params)
    {
        return ApiService.get('/api/briefings', params);
    },

    get(id)
    {
        return ApiService.get(`/api/briefings/${id}`);
    },

    update(id, data)
    {
        return ApiService.put(`/api/briefings/${id}`, data);
    },

    publish(id)
    {
        return ApiService.put(`/api/briefings/${id}/publish`);
    },

    unpublish(id)
    {
        return ApiService.put(`/api/briefings/${id}/unpublish`);
    },

    rate(id, params)
    {
        return ApiService.put(`/api/briefings/${id}/rate`, params);
    },

    favorite(id)
    {
        return ApiService.put(`/api/briefings/${id}/favorite`);
    },

    create(data)
    {
        return ApiService.post('/api/briefings', data);
    },

    remove(id)
    {
        return ApiService.delete(`/api/briefings/${id}`);
    },
};

export {BriefingService};
