<template>
    <div class="icph-page-contact">
        <h1 class="icph-page-title">Contact</h1>
        <span class="icph-section-title">Send a message to the team</span>
        <form @submit.prevent="send" :class="{ 'icph-loading': sending }">
            <loader />
            <div class="icph-contact-panel">
                <p v-if="success" class="icph-contact-success">Message successfully sent</p>
                <label>Message</label>
                <div class="icph-contact-message-holder">
                    <textarea v-model="model.message"></textarea>
                </div>
            </div>
            <Button type="submit" class="icph-send-button" :round="true" color="primary">
                SEND
            </Button>
        </form>
    </div>
</template>

<script>
    import Button from '@/components/Buttons/Button';
    import Loader from  '@/components/Loader';

    import { ContactService } from '@/services';

    export default
    {
        name: 'Contact',
        components:
        {
            Loader,
            Button,
        },
        data()
        {
            return {
                sending: false,
                success: false,
                model:
                {
                    message: this.$route.params.message || '',
                }
            };
        },
        mounted()
        {
            this.model.message = this.$route.params.message || '';
        },
        methods:
        {
            async send()
            {
                this.sending = true;
                this.success = (await ContactService.send(this.model)).data.success;
                this.sending = false;
            },
        },
    }
</script>
