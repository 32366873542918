<template>
    <div class="icph-articles" :class="{ 'icph-loading': loading }">
        <loader />
        <slot></slot>
        <Button class="icph-view-all" v-if="!hideViewAll" icon="icon-read-more" color="primary" @click="$router.push('/briefings')">
            View All Briefings
        </Button>
    </div>
</template>

<script>
    import Loader from  '@/components/Loader';
    import Button from '@/components/Buttons/Button';

    export default
    {
        name: 'articles',
        components:
        {
            Loader,
            Button,
        },
        props:
        {
            loading: Boolean,
            hideViewAll: Boolean,
        },
    }
</script>
