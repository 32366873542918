<template>
    <div
        class="icph-slack-channel"
        :class="{
            'icph-loading': loading
        }"
    >
        <ul class="icph-slack-channel-list">
            <!-- <li @click="filter_channels('all')" :class="{'icph-selected': this.channel === 'all'}">All</li> -->
            <li
                @click="filter_channels(channel.id)"
                v-for="(channel, key) in channels"
                :key="'channel_' + key"
                :class="{'icph-selected': this.channel === channel.id}"
                >#{{ channel.name }}</li>
        </ul>
        <div class="icph-slack-channel-viewport" ref="viewport">
            <div class="icph-slack-channel-inner" ref="sview">
                <template v-for="(message, index) in messages" :key="index">
                    <div class="icph-slack-message" v-if="message.type === 'message'">
                        <img :src="message.user.image" />
                        <div class="icph-slack-meta">
                            <span class="icph-slack-name">{{ message.user.name || '' }}</span>
                            <span class="icph-slack-time">{{ message.time || ''}}</span>
                        </div>
                        <p class="icph-slack-content" v-html="message.text || ''"></p>
                    </div>
                    <span class="icph-slack-date-separator" v-if="message.type === 'date'">
                        <span v-html="message.date || ''"></span>
                    </span>
                </template>
            </div>
            <loader />
        </div>
        <nav class="icph-slack-nav">
            <button class="icph-scroll-up" @click="scroll_up"></button>
            <button class="icph-scroll-down" @click="scroll_down"></button>
        </nav>
    </div>
</template>

<script>
    import { SlackService } from '@/services';
    import Loader from '@/components/Loader';

    export default
    {
        name: 'slack-channel',
        props:
        {
        },
        components:
        {
            Loader,
        },
        data()
        {
            return {
                offset_top: 0,
                messages: [],
                filtered_messages: [],
                channels: {},
                // channel: 'all',
                channel: 'C01140QP1A8', // #general
                // channel: 'C0126Q4UJK0', // #finance
                loading: false,
                observer: null,
            };
        },
        async mounted()
        {
            await this.get_messages();
            this.update_observer();
        },
        methods:
        {
            async get_messages()
            {
                this.loading = true;
                const response = await SlackService.all(this.channel);

                this.messages = response.data.messages;
                this.channels = response.data.channels;
                // this.offset_top = 0;
                // this.$refs.sview.style.transform = `translateY(${this.offset_top}px)`;

                setTimeout(function ()
                {
                    this.loading = false;
                }.bind(this), 100);
            },

            scroll_up()
            {
                const el = this.$refs.sview;
                const viewport_height = this.$refs.viewport.offsetHeight;

                this.offset_top = Math.min(0, this.offset_top + viewport_height);

                el.style.transform = `translateY(${this.offset_top}px)`;
            },

            scroll_down()
            {
                const el = this.$refs.sview;
                const viewport_height = this.$refs.viewport.offsetHeight;

                this.offset_top = Math.min(el.offsetHeight - viewport_height, this.offset_top - viewport_height);

                el.style.transform = `translateY(${this.offset_top}px)`;
            },

            update_observer()
            {
                if (this.observer)
                {
                    this.$refs.sview.querySelectorAll('.icph-slack-message, .icph-slack-date-separator').forEach((el) =>
                    {
                        this.observer.unobserve(el);
                    });

                    this.observer.disconnect();
                    this.observer = null;
                }

                this.observer = new IntersectionObserver((entries) =>
                {
                    entries.forEach((entry) =>
                    {
                        entry.target.classList.toggle('icph-visible', entry.isIntersecting);
                    });
                },
                {
                    root: document.querySelector('#app > .ps'),
                    rootMargin: '0px',
                    threshold: 0,
                });

                this.$refs.sview.querySelectorAll('.icph-slack-message, .icph-slack-date-separator').forEach((el) =>
                {
                    this.observer.observe(el);
                });
            },

            async filter_channels(channel_id)
            {
                document.querySelector('#app > .ps').scrollTo(0, 0, 'smooth');

                if (this.observer)
                {
                    this.$refs.sview.querySelectorAll('.icph-slack-message, .icph-slack-date-separator').forEach((el) =>
                    {
                        this.observer.unobserve(el);
                    });
                }

                this.channel = channel_id;
                await this.get_messages();
                this.update_observer();
            }
        },
    }
</script>
