<template>
    <div class="icph-page-slack">
        <h1 class="icph-page-title">Live Slack Channel / #ICPH</h1>
        <slack-channel>
        </slack-channel>
    </div>
</template>

<script>
    import SlackChannel from '@/components/SlackChannel';

    export default
    {
        name: 'Slack',
        components:
        {
            SlackChannel,
        },
        data()
        {
            return {
            };
        },
    }
</script>
