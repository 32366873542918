<template>
    <div class="icph-admin-create" :class="{ 'icph-loading': loading, 'icph-fullscreen': fullscreen, }">
        <button class="icph-fullscreen-button" @click="this.fullscreen = !this.fullscreen;"></button>
        <loader />
        <perfect-scrollbar
            tag="form"
            @submit.prevent="save"
            :options="{ suppressScrollX: true }"
        >
            <header>
                <base-input
                    :round="true"
                    :error="get_errors('title')"
                    v-model="model.title"
                    label="Title"
                    placeholder="Enter Document Title"
                    >
                </base-input>
                <date-picker
                    v-if="!is_new"
                    label="Date &amp; Time"
                    placeholder="Select date"
                    mode="dateTime"
                    :round="true"
                    v-model="model.published_at"
                    >
                </date-picker>
                <dropdown
                    label="Document Type"
                    placeholder="Select your document type"
                    v-model="model.type"
                    :round="true"
                    :options="types"
                    >
                </dropdown>
                <!-- :error="get_errors('type')" -->
                <dropdown
                    label="Topic"
                    placeholder="Select your topics"
                    v-model="model.topics"
                    mode="tags"
                    :closeOnSelect="false"
                    :createTag="true"
                    :round="true"
                    :options="topics"
                    >
                </dropdown>
                <dropdown
                    label="Geography"
                    placeholder="Select countries"
                    v-model="model.countries"
                    mode="tags"
                    :round="true"
                    :options="countries"
                >
                </dropdown>
            </header>
            <base-textarea
                :error="get_errors('summary')"
                v-model="model.summary"
                label="Summary"
                placeholder="Enter Document Summary"
                >
            </base-textarea>
            <div class="icph-tinymce-holder">
                <editor
                    :init="tinymce_options"
                    v-model="model.content"
                />
            </div>
            <footer>
                <upload-button
                    :round="true"
                    color="primary"
                    accept="pdf"
                    change-caption="CHANGE PDF"
                    v-model="model.pdf_file"
                    >
                    UPLOAD PDF
                </upload-button>
                <upload-button
                    :round="true"
                    color="primary"
                    change-caption="CHANGE IMAGE"
                    accept="images"
                    v-model="model.image_file"
                    >
                    UPLOAD IMAGE
                </upload-button>
                <SwitchButton
                    v-model:checked="model.publish"
                    on-text="PUBLISHED"
                    off-text="DRAFT"
                    >
                </SwitchButton>
                <Button
                    class="icph-button-favorite"
                    :class="{ 'icph-selected': model.favorite }"
                    :round="true"
                    @click.prevent="set_favorite()"
                    >
                    FAVORITE
                </Button>
                <Button
                    class="icph-cancel"
                    :round="true"
                    @click.prevent="$router.push({ name: 'DocumentsList' })"
                    >
                    CANCEL
                </Button>
                <Button
                    class="icph-save"
                    :round="true"
                    @click.prevent="save"
                    >
                    SAVE
                </Button>
            </footer>
            <a
                class="icph-pdf-link"
                target="_blank"
                v-if="brief_pdf_link"
                :href="brief_pdf_link"
                >
                {{ brief_pdf_link }}
            </a>
        </perfect-scrollbar>
    </div>
</template>

<script>
    import Editor from '@tinymce/tinymce-vue';
    import BaseInput from '@/components/Inputs/BaseInput';
    import BaseTextarea from '@/components/Inputs/BaseTextarea';
    import Dropdown from '@/components/Inputs/Dropdown';
    import DatePicker from '@/components/Inputs/DatePicker';
    import Button from '@/components/Buttons/Button';
    import UploadButton from '@/components/Buttons/UploadButton';
    import SwitchButton from '@/components/Buttons/SwitchButton';
    import Loader from '@/components/Loader';

    import useVuelidate from '@vuelidate/core';
    import { required, minLength } from '@vuelidate/validators';

    import dayjs from 'dayjs';
    import customParseFormat from 'dayjs/plugin/customParseFormat';

    import { BriefingService, TopicService, CountryService, UploadService, RegionService } from '@/services';

    export default
    {
        name: 'DocumentsCreate',
        components:
        {
            'editor': Editor,
            BaseInput,
            BaseTextarea,
            Dropdown,
            DatePicker,
            Button,
            UploadButton,
            SwitchButton,
            Loader,
        },
        setup()
        {
            return { v$: useVuelidate() };
        },
        data()
        {
            return {
                is_new: !this.$route.params.id,
                loading: false,
                fullscreen: false,
                tinymce_options: {
                    height: 320,
                    menubar: false,
                    relative_urls: false,
                    remove_script_host: false,
                    convert_urls: false,
                    image_uploadtab: true,
                    images_upload_url: process.env.VUE_APP_BACKEND_URL + 'api/uploads/tinymce',
                    images_upload_credentials: true,
                    images_upload_handler: this.handle_tinymce_image_upload,
                    powerpaste_allow_local_images: true,
                    powerpaste_word_import: 'prompt',
                    powerpaste_html_import: 'prompt',
                    plugins:
                    [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table code help wordcount',
                        'powerpaste advcode advtable formatpainter',
                    ],
                    toolbar:
                        'undo redo | formatselect | sizeselect bold italic underline backcolor forecolor fontsizeselect | \
                        alignleft aligncenter alignright alignjustify | \
                        anchor link | \
                        table tableinsertdialog tablecellprops tableprops advtablerownumbering | \
                        bullist numlist outdent indent | formatpainter | removeformat | image | help',
                    formats:
                    {
                        borderstyle:
                        {
                            selector: 'td,th',
                            styles:
                            {
                                borderTopStyle: 'solid',
                                borderRightStyle: 'solid',
                                borderBottomStyle: 'solid',
                                borderLeftStyle: 'solid',
                            },
                            remove_similar: true
                        },
                        bordercolor:
                        {
                            selector: 'td,th',
                            styles:
                            {
                                borderTopColor: '#32CD32',
                                borderRightColor: '#32CD32',
                                borderBottomColor: '#32CD32',
                                borderLeftColor: '#32CD32'
                            },
                            remove_similar: true
                        },
                        backgroundcolor:
                        {
                            selector: 'td,th',
                            styles: { backgroundColor: '#006400' },
                            remove_similar: true
                        },
                        formatpainter_removeformat:
                        [
                            {
                                selector: 'b,strong,em,i,font,u,strike,sub,sup,dfn,code,samp,kbd,var,cite,mark,q,del,ins',
                                remove: 'all',
                                split: true,
                                expand: false,
                                block_expand: true,
                                deep: true
                            },
                            {
                                selector: 'span',
                                attributes: ['style', 'class'],
                                remove: 'empty',
                                split: true,
                                expand: false,
                                deep: true
                            },
                            {
                                selector: '*:not(tr,td,th,table)',
                                attributes: ['style', 'class'],
                                split: false,
                                expand: false,
                                deep: true
                            }
                        ]
                    },
                },
                types: [
                    'Issues and Background',
                    'Key Processes and Opportunities',
                    'Country Snapshots',
                    'Country Strategies',
                ],
                topics: [],
                countries: [],
                brief_pdf_link: null,
                model:
                {
                    title: '',
                    summary: '',
                    type: '',
                    topics: [],
                    countries: [],
                    content: '',
                    published_at: '',
                    publish: false,
                    pdf_file: '',
                    image_file: '',
                },
            };
        },
        validations()
        {
            return {
                model:
                {
                    title: { required, minLength: minLength(3) },
                    summary: { required, minLength: minLength(3) },
                    type: { required },
                },
            };
        },
        async created()
        {
            this.loading = true;

            this.countries = (await CountryService.all()).data;
            this.topics = (await TopicService.all()).data;
            this.regions = (await RegionService.all()).data;

            this.countries.forEach((item) =>
            {
                item.value = item.id;
                item.label = item.name;
            });

            this.regions.forEach((item) => {
                item.value = item.code;
                item.label = "Region: " + item.name;
            });

            this.countries = [...this.regions, ...this.countries];

            this.topics.forEach((item) =>
            {
                item.value = item.name;
                item.label = item.name;
            });

            if (!this.is_new)
            {
                const data = (await BriefingService.get(this.$route.params.id)).data;

                this.model = { ...this.model, ...data };
                this.model.publish = !!this.model.published_at;

                if (this.model.countries)
                {
                    this.model.countries = this.model.countries.map((item) => item.id);
                }

                if (this.model.publish)
                {
                    dayjs.extend(customParseFormat);
                    this.model.published_at = dayjs(this.model.published_at, 'DD/MM/YYYY hh:mm A').toDate();
                }

                if (this.model.pdf_file && this.model.pdf_file.id)
                {
                    this.brief_pdf_link = process.env.VUE_APP_BACKEND_URL + 'api/files/' + this.model.pdf_file.id;
                }
            }

            this.loading = false;
        },
        methods:
        {
            async save()
            {
                const is_valid = await this.v$.$validate();

                if (!is_valid)
                {
                    return ;
                }

                let form_data = new FormData();

                form_data.append('title', this.model.title);
                form_data.append('summary', this.model.summary);
                form_data.append('type', this.model.type);
                form_data.append('topics', this.model.topics);
                form_data.append('countries', this.model.countries);
                form_data.append('content', this.model.content);
                form_data.append('favorite', this.model.favorite ? 1 : 0);
                form_data.append('published_at', this.model.published_at instanceof Date ? this.model.published_at.toISOString().slice(0, 19).replace('T', ' ') : this.model.published_at);
                form_data.append('publish', this.model.publish ? 1 : 0);
                form_data.append('pdf_file', this.model.pdf_file);
                form_data.append('image_file', this.model.image_file);

                if (this.is_new)
                {
                    BriefingService.create(form_data).then(() =>
                    {
                        this.$router.push({ name: 'DocumentsList' });
                        this.$toast.success('Document was successfully created!');
                    },
                    (error) =>
                    {
                        if (error.data.message)
                        {
                            this.$toast.error(error.data.message);
                        }
                        else
                        {
                            this.$toast.error('Something went wrong. Please try again!');
                        }
                    });
                }
                else
                {
                    BriefingService.update(this.$route.params.id, form_data).then(() =>
                    {
                        this.$toast.success('Document was successfully updated!');
                    },
                    (error) =>
                    {
                        if (error.data.message)
                        {
                            this.$toast.error(error.data.message);
                        }
                        else
                        {
                            this.$toast.error('Something went wrong. Please try again!');
                        }
                    });
                }
            },

            set_favorite()
            {
                this.model.favorite = !this.model.favorite;
            },

            handle_tinymce_image_upload(blobInfo, success)
            {
                const form_data = new FormData;

                form_data.append('file', blobInfo.blob(), blobInfo.filename());

                UploadService.tinymce(form_data).then((response) =>
                {
                    success(response.data.location);
                });
            },

            get_errors(field, message)
            {
                const $err = this.v$.model[field];

                if ($err.$error)
                {
                    return message || $err.$errors.map((err) => err.$message).join(' - ');
                }

                return false;
            }
        }
    }
</script>
