<template>
    <div class="icph-admin-list">
        <header class="icph-admin-list-header">
            <h1>List of Documents &amp; Their Status</h1>
            <router-link class="icph-button icph-color-primary" :to="{ name: 'DocumentsCreate' }">CREATE NEW DOCUMENT</router-link>
            <panel
                class="icph-search-panel"
                :dark="true"
                >
                <base-input
                    :round="true"
                    v-model="search_term"
                    label="Search briefings..."
                    placeholder="Search"
                    @change="filter_documents"
                    >
                </base-input>
                <Button class="icph-search-button" icon="icon-search" color="primary" @click="filter_documents">
                    Search
                </Button>
            </panel>
        </header>
        <div class="icph-table" :class="{ 'icph-loading': loading }">
            <header>
                <div class="icph-table-col">#</div>
                <div class="icph-table-col">&#9733;</div>
                <div class="icph-table-col icph-sortable">Title</div>
                <div class="icph-table-col">Type</div>
                <div class="icph-table-col">Published Date</div>
                <div class="icph-table-col">Last modified time</div>
                <div class="icph-table-col">Author</div>
                <div class="icph-table-col">Status</div>
                <div class="icph-table-col">Action</div>
            </header>
            <div class="icph-table-body">
                <loader />
                <p class="icph-table-empty" v-if="!has_briefings && !loading">There are no items to display.</p>
                <perfect-scrollbar v-else :options="{ suppressScrollX: true }">
                    <div class="icph-table-row" v-for="(briefing, key) in filtered_briefings" :key="key">
                        <div class="icph-table-col">{{ key + 1 }}</div>
                        <div class="icph-table-col">
                            <button
                                class="icph-button-favorite"
                                :class="{ 'icph-selected': briefing.favorite }"
                                @click="set_favorite(briefing)"
                                >
                            </button>
                        </div>
                        <div class="icph-table-col">{{ briefing.title }}</div>
                        <div class="icph-table-col">{{ briefing.type || 'Briefing' }}</div>
                        <div class="icph-table-col">{{ briefing.published_at }}</div>
                        <div class="icph-table-col">{{ briefing.updated_at }}</div>
                        <div class="icph-table-col">{{ briefing.author.name || '' }}</div>
                        <div class="icph-table-col icph-no-bg">
                            <SwitchButton
                                v-model:checked="briefing._published"
                                on-text="PUBLISHED"
                                off-text="DRAFT"
                                @input="set_publish(briefing)"
                                >
                            </SwitchButton>
                        </div>
                        <div class="icph-table-col icph-no-bg">
                            <router-link
                                class="icph-button icph-color-primary icph-button-edit"
                                :to="{ name: 'DocumentsEdit', params: { id: briefing.id } }"
                                >
                            </router-link>
                            <button
                                class="icph-button icph-color-primary icph-button-remove"
                                @click="remove_briefing(briefing, key)"
                                >
                            </button>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
    </div>
</template>

<script>
    import SwitchButton from '@/components/Buttons/SwitchButton';
    import Button from '@/components/Buttons/Button';
    import Panel from '@/components/Panels/Panel';
    import BaseInput from '@/components/Inputs/BaseInput';
    import Loader from '@/components/Loader';

    import { BriefingService } from '@/services';

    export default
    {
        name: 'DocumentsList',
        components:
        {
            SwitchButton,
            Button,
            Panel,
            BaseInput,
            Loader,
        },
        data()
        {
            return {
                loading: false,
                has_briefings: false,
                briefings: [],
                filtered_briefings: [],
            };
        },
        async mounted()
        {
            this.loading = true;

            this.briefings = (await BriefingService.all({ all: 1, 'per-page': -1 })).data.data;

            this.briefings.forEach((brief) => { brief._published = !!brief.published_at });

            this.filtered_briefings = this.briefings;

            if (this.briefings && this.briefings.length)
            {
                this.has_briefings = true;
            }

            this.loading = false;
        },
        methods:
        {
            async set_publish(briefing)
            {
                if (briefing._published)
                {
                    briefing.published_at = (await BriefingService.publish(briefing.id)).data.published_at;
                }
                else
                {
                    await BriefingService.unpublish(briefing.id);
                    briefing.published_at = '';
                }
            },

            async set_favorite(briefing)
            {
                briefing.favorite = !briefing.favorite;

                this.briefings.forEach((brief) =>
                {
                    brief.id !== briefing.id ? brief.favorite = false : '';
                });

                await BriefingService.favorite(briefing.id);
            },

            async remove_briefing(briefing, index)
            {
                if (confirm('Are you sure you want to remove this briefing?'))
                {
                    await BriefingService.remove(briefing.id);

                    this.briefings = this.briefings.splice(index, 1);
                }
            },

            filter_documents()
            {
                const search_term = this.search_term.toLowerCase();

                this.filtered_briefings = this.briefings.filter((brief) =>
                {
                    return (brief.title + ' ' + brief.type).toLowerCase().includes(search_term);
                });

                this.has_briefings = this.filtered_briefings && this.filtered_briefings.length;
            }
        }
    }
</script>
