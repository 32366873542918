<template>
    <div
        class="icph-checkbox"
        :class="{
            'icph-disabled': disabled,
        }">
        <label class="icph-checkbox-inner" :for="unique_id">
            <input
                type="radio"
                :disabled="disabled"
                :id="unique_id"
                :checked="is_checked"
                :value="value"
                :name="name"
                @input="$emit('update:modelValue', $event.target.value)"
                />
            <span class="icph-checkbox-checkmark"></span>
            <span class="icph-checkbox-label"><slot></slot></span>
        </label>
    </div>
</template>

<script>
    export default
    {
        name: 'radiobox',
        props:
        {
            value: String,
            modelValue: { default: '' },
            name: String,
            disabled: Boolean,
        },
        model:
        {
            prop: 'modelValue',
            // event: 'input',
        },
        emits: ['update:modelValue'],
        data()
        {
            return {
                unique_id: '',
                touched: false,
            };
        },
        computed:
        {
            is_checked()
            {
                return this.modelValue === this.value;
            },
        },
        created()
        {
            this.unique_id = Math
                .random()
                .toString(16)
                .slice(2);
        },
    }
</script>
