import { ApiService } from './api-service';

const CalendarService =
{
    all(params)
    {
        return ApiService.get('/api/calendar/events', params);
    },

    invite(params)
    {
        return ApiService.post('/api/calendar/invite', params);
    },
};

export {CalendarService};
