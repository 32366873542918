<template>
    <div
        class="icph-switch-button"
        :class="[
        switch_class,
        {
            'icph-error': error,
        }]"
        @click="toggle()"
        >
        <div class="icph-switch-button-inner">
            <span class="icph-switch-on-text">
                <slot name="onText">{{ onText }}</slot>
            </span>
            <span class="icph-switch-thumb"></span>
            <span class="icph-switch-off-text">
                <slot name="offText">{{ offText }}</slot>
            </span>
        </div>
        <slot name="error" v-if="error || $slots.error">
            <p class="icph-error">{{ error }}</p>
        </slot>
    </div>
</template>

<script>
    export default
    {
        name: 'SwitchButton',
        props:
        {
            checked:
            {
                type: Boolean,
                required: true,
            },
            error:
            {
                type: [String, Boolean],
                default: ''
            },
            onText: String,
            offText: String,
        },
        computed:
        {
            switch_class()
            {
                return 'icph-switch-' + (this.model ? 'on' : 'off');
            },
            model:
            {
                get()
                {
                    return this.checked;
                },
                set(value)
                {
                    this.$emit('update:checked', value);
                }
            },
        },
        methods:
        {
            toggle()
            {
                this.model = !this.model;
                this.$emit('input', this.model);
            },
        },
    }
</script>
