<template>
    <perfect-scrollbar :options="{ suppressScrollX: true }">
        <router-view />
    </perfect-scrollbar>
</template>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;0,800;1,400;1,700;1,800&display=swap');
</style>

<style lang="less">
    #app
    {
        font-family: 'Montserrat', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
</style>
